import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { User, CreditCard, Phone, Mail, Globe } from "react-feather";
import Sidebar from "../components/Sidebar";

const CustomerDetail = () => {
  const { customer_id } = useParams();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/loyalty/${customer_id}`
        );
        setCustomer(response.data.customer);
      } catch (error) {
        console.error("Error fetching customer:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCustomer();
  }, [customer_id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-green-400 to-blue-500">
        <TailSpin height="80" width="80" color="#ffffff" visible />
      </div>
    );
  }

  if (!customer) {
    return (
      <div className="flex justify-center items-center h-screen text-white bg-gradient-to-r from-green-400 to-blue-500">
        <h2 className="text-2xl font-semibold">Customer not found</h2>
      </div>
    );
  }

  return (
    <Sidebar>
      <div className="min-h-screen bg-gradient-to-br from-green-500 via-blue-500 to-purple-600 flex justify-center items-center">
        <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-lg mx-4">
          <h1 className="text-3xl font-extrabold text-gray-800 text-center mb-6">
            {customer.customer_name}
          </h1>
          <div className="space-y-4 text-gray-700">
            <div className="flex items-center space-x-3">
              <CreditCard className="text-blue-500" />
              <p>
                <strong>National ID:</strong> {customer.national_id || "N/A"}
              </p>
            </div>
            <div className="flex items-center space-x-3">
              <CreditCard className="text-purple-500" />
              <p>
                <strong>Passport No:</strong> {customer.passport_no || "N/A"}
              </p>
            </div>
            <div className="flex items-center space-x-3">
              <User className="text-green-500" />
              <p>
                <strong>Gender:</strong> {customer.gender || "N/A"}
              </p>
            </div>
            <div className="flex items-center space-x-3">
              <Phone className="text-red-500" />
              <p>
                <strong>Phone:</strong> {customer.phone || "N/A"}
              </p>
            </div>
            <div className="flex items-center space-x-3">
              <Mail className="text-yellow-500" />
              <p>
                <strong>Email:</strong> {customer.primary_email || "N/A"}
              </p>
            </div>
            <div className="flex items-center space-x-3">
              <Globe className="text-indigo-500" />
              <p>
                <strong>Country of Residence:</strong>{" "}
                {customer.country_of_residence || "N/A"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default CustomerDetail;
