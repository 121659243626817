// WorkDiaryEntry.jsx
import Sidebar from "./Sidebar";
import axios from "axios";
import {
  getISOWeek,
  differenceInHours,
  isAfter,
  subHours,
  parseISO,
} from "date-fns";
import { motion, AnimatePresence } from "framer-motion";
import {
  Clock,
  Calendar,
  Briefcase,
  FileText,
  MessageSquare,
  CheckCircle,
  XCircle,
  Edit,
  Trash,
  User,
} from "lucide-react";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

const WorkDiaryEntry = () => {
  const userFromRedux = useSelector((state) => state.user.user);
  const tokenFromRedux = useSelector((state) => state.user.token);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState({
    supervisor: false,
    entries: false,
    focusAreas: false,
  });

  const [formData, setFormData] = useState({
    date: "",
    startTime: "",
    endTime: "",
    focusArea: "",
    newFocusArea: "",
    activityDescription: "",
    comments: "",
    supervisorId: "",
    weekNumber: "",
  });

  const [entries, setEntries] = useState([]);
  const [supervisor, setSupervisor] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [showEntries, setShowEntries] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [focusAreas, setFocusAreas] = useState([]);
  const [showNewFocusAreaFields, setShowNewFocusAreaFields] = useState(false);
  const [lastLoggedActivity, setLastLoggedActivity] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  // Fetch supervisor information
  const fetchSupervisor = useCallback(async () => {
    setLoading((prev) => ({ ...prev, supervisor: true }));
    try {
      const response = await axios.get(
        `https://workspace.optiven.co.ke/api/work-diary/supervisor/${userFromRedux.user_id}`,
        { headers: { Authorization: `Bearer ${tokenFromRedux}` } }
      );
      setSupervisor(response.data);
      setFormData((prevData) => ({
        ...prevData,
        supervisorId: response.data.user_id,
      }));
    } catch (error) {
      console.error("Error fetching supervisor information:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        supervisor:
          error.response?.data?.message ||
          "Failed to fetch supervisor information.",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, supervisor: false }));
    }
  }, [userFromRedux, tokenFromRedux]);

  // Fetch entries for the user
  const fetchEntries = useCallback(async () => {
    setLoading((prev) => ({ ...prev, entries: true }));
    try {
      const response = await axios.get(
        `https://workspace.optiven.co.ke/api/work-diary/activities/${userFromRedux.user_id}`,
        {
          headers: { Authorization: `Bearer ${tokenFromRedux}` },
        }
      );
      const entriesData = Array.isArray(response.data) ? response.data : [];
      setEntries(entriesData);
    } catch (error) {
      console.error("Error fetching entries:", error);
      handleErrors(error, (msg) => {
        setErrors((prevErrors) => ({ ...prevErrors, entries: msg }));
      });
    } finally {
      setLoading((prev) => ({ ...prev, entries: false }));
    }
  }, [userFromRedux.user_id, tokenFromRedux]);

  // Fetch focus areas for the user
  const fetchFocusAreas = useCallback(async () => {
    setLoading((prev) => ({ ...prev, focusAreas: true }));
    try {
      const response = await axios.get(
        `https://workspace.optiven.co.ke/api/work-diary/focus-areas/${userFromRedux.user_id}`,
        { headers: { Authorization: `Bearer ${tokenFromRedux}` } }
      );
      const focusAreasData = Array.isArray(response.data) ? response.data : [];
      setFocusAreas(focusAreasData);
    } catch (error) {
      console.error("Error fetching focus areas:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        focusAreas:
          error.response?.data?.message || "Failed to fetch focus areas.",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, focusAreas: false }));
    }
  }, [userFromRedux, tokenFromRedux]);

  // Fetch the last logged activity
  const fetchLastLoggedActivity = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://workspace.optiven.co.ke/api/work-diary/last-activity/${userFromRedux.user_id}`,
        { headers: { Authorization: `Bearer ${tokenFromRedux}` } }
      );
      setLastLoggedActivity(response.data);
    } catch (error) {
      console.error("Error fetching last logged activity:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        lastActivity:
          error.response?.data?.message ||
          "Failed to fetch last logged activity.",
      }));
    }
  }, [tokenFromRedux, userFromRedux]);

  // Initialize form data and fetch required data
  useEffect(() => {
    if (!formData.date) {
      const currentDate = new Date();
      const isoDate = currentDate.toISOString().split("T")[0];
      const weekNum = getISOWeek(currentDate);
      setFormData((prevData) => ({
        ...prevData,
        date: isoDate,
        weekNumber: weekNum,
      }));
    }

    if (userFromRedux) {
      fetchSupervisor();
      fetchFocusAreas();
      fetchLastLoggedActivity();
      fetchEntries();
    }
  }, [
    userFromRedux,
    fetchSupervisor,
    fetchFocusAreas,
    fetchLastLoggedActivity,
    fetchEntries,
    formData.date,
  ]);

  // Fetch entries whenever formData.date changes
  useEffect(() => {
    if (userFromRedux && formData.date) {
      fetchEntries();
    }
  }, [formData.date, fetchEntries, userFromRedux]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFieldErrors((prev) => ({ ...prev, [name]: "" }));

    if (name === "date") {
      const date = new Date(value);
      const currentDate = new Date();
      const fortyEightHoursAgo = subHours(currentDate, 48);
      const weekNum = getISOWeek(date);

      setFormData((prevData) => ({
        ...prevData,
        date: value,
        weekNumber: weekNum,
      }));

      if (isAfter(date, currentDate)) {
        setFieldErrors((prev) => ({
          ...prev,
          date: "Date cannot be in the future.",
        }));
      } else if (isAfter(fortyEightHoursAgo, date)) {
        setFieldErrors((prev) => ({
          ...prev,
          date: "Entries can only be made for the last 48 hours.",
        }));
      }
    } else if (name === "focusArea") {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      setShowNewFocusAreaFields(value === "add_new");
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  // Handle deletion of an entry
  const handleDelete = async (id) => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this entry?"
      );
      if (!confirmed) return;

      await axios.delete(
        `https://workspace.optiven.co.ke/api/work-diary/delete-entry/${id}`,
        {
          headers: { Authorization: `Bearer ${tokenFromRedux}` },
        }
      );

      setEntries((prevEntries) =>
        prevEntries.filter((entry) => entry.id !== id)
      );
      setSuccess("Entry deleted successfully!");
    } catch (error) {
      console.error("Error deleting entry:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        delete: "Error deleting the entry. Please try again.",
      }));
    }
  };

  // Handle editing of an entry
  const handleEdit = (entry) => {
    const startDateTimeLocal = parseDateTime(entry.date, entry.start_time);
    const endDateTimeLocal = parseDateTime(entry.date, entry.end_time);
  
    setIsEditing(true);
    setEditId(entry.id);
    setFormData({
      date: startDateTimeLocal.toISOString().split("T")[0],
      startTime: startDateTimeLocal.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' }),
      endTime: endDateTimeLocal.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' }),
      focusArea: entry.focus_area,
      activityDescription: entry.activity_description,
      comments: entry.comments || "",
      supervisorId: entry.supervisor_id,
      weekNumber: entry.week_number,
    });
    setIsModalOpen(true);
  };
  

  // Clear form data and reset states
  const clearForm = () => {
    setFormData((prevData) => ({
      ...prevData,
      startTime: "",
      endTime: "",
      focusArea: "",
      newFocusArea: "",
      activityDescription: "",
      comments: "",
      supervisorId: supervisor?.user_id || "",
    }));
    setFieldErrors({});
    setIsEditing(false);
    setEditId(null);
    setIsModalOpen(false);
  };

  // Helper function to get the ISO week number
  const getWeekNumber = (date) => {
    return getISOWeek(date);
  };

  // Define isWeekClosed function
  const isWeekClosed = (date) => {
    const entryDate = new Date(date);
    const entryWeekNumber = getWeekNumber(entryDate);
    const currentWeekNumber = getWeekNumber(new Date());

    if (entryWeekNumber < currentWeekNumber) {
      return true;
    } else if (entryWeekNumber === currentWeekNumber) {
      const currentDate = new Date();
      const weekClosingTime = new Date();

      // Set to Saturday at 4 PM
      weekClosingTime.setDate(
        weekClosingTime.getDate() + (6 - currentDate.getDay())
      );
      weekClosingTime.setHours(16, 0, 0, 0);

      if (currentDate > weekClosingTime) {
        return true;
      }
    }
    return false;
  };

  // Check for overlapping time entries
  const isOverlapping = (
    newStartTime,
    newEndTime,
    existingEntries,
    date,
    editId = null
  ) => {
    const newStart = new Date(`${date}T${newStartTime}`);
    const newEnd = new Date(`${date}T${newEndTime}`);
  
    for (let entry of existingEntries) {
      if (editId && entry.id === editId) continue;
  
      if (entry.date !== date) continue;
  
      const entryStart = parseDateTime(entry.date, entry.start_time);
      const entryEnd = parseDateTime(entry.date, entry.end_time);
  
      if (newStart < entryEnd && newEnd > entryStart) {
        return true;
      }
    }
    return false;
  };
  
  

  // Adjusted validateForm function
  const validateForm = () => {
    const errors = {};
    const {
      date,
      startTime,
      endTime,
      focusArea,
      activityDescription,
      newFocusArea,
    } = formData;
  
    if (!date) errors.date = "Date is required";
    if (!startTime) errors.startTime = "Start time is required";
    if (!endTime) errors.endTime = "End time is required";
    if (!focusArea) errors.focusArea = "Focus area is required";
    if (showNewFocusAreaFields && !newFocusArea)
      errors.newFocusArea = "New focus area is required";
    if (!activityDescription)
      errors.activityDescription = "Activity description is required";
  
    // Create Date objects in local time
    const entryStartDateTime = new Date(`${date}T${startTime}`);
    const entryEndDateTime = new Date(`${date}T${endTime}`);
    const currentDate = new Date();
    const fortyEightHoursAgo = subHours(currentDate, 48);
    const workStart = new Date(`${date}T08:00:00`);
    const workEnd = new Date(`${date}T17:00:00`);
  
    // Time validations
    if (entryStartDateTime >= entryEndDateTime) {
      errors.endTime = "End time must be after start time";
    }
    if (entryStartDateTime < workStart) {
      errors.startTime = "Start time must be after 8 AM";
    }
    if (entryEndDateTime > workEnd) {
      errors.endTime = "End time must be before 5 PM";
    }
  
    // Date and time validations
    if (isAfter(entryStartDateTime, currentDate)) {
      errors.startTime = "Start time cannot be in the future";
    }
    if (isAfter(entryEndDateTime, currentDate)) {
      errors.endTime = "End time cannot be in the future";
    }
    if (isAfter(fortyEightHoursAgo, entryStartDateTime)) {
      errors.startTime = "Entries can only be made for the last 48 hours";
    }
    if (isAfter(fortyEightHoursAgo, entryEndDateTime)) {
      errors.endTime = "Entries can only be made for the last 48 hours";
    }
  
    // Duration validation (maximum 2 hours)
    const duration = (entryEndDateTime - entryStartDateTime) / (1000 * 60 * 60);
    if (duration > 2) {
      errors.endTime = "Maximum allowed duration is 2 hours per entry.";
    }
  
    // Overlapping entries check
    const existingEntries = entries || [];
    if (
      isOverlapping(
        startTime,
        endTime,
        existingEntries,
        date,
        isEditing ? editId : null
      )
    ) {
      errors.startTime = "The selected time overlaps with another entry.";
      errors.endTime = "The selected time overlaps with another entry.";
    }
  
    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };
  

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(null);
    setErrors((prevErrors) => ({ ...prevErrors, submit: null }));
  
    if (!validateForm()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        submit: "Please fill in all required fields correctly.",
      }));
      return;
    }
  
    setIsSubmitting(true);
    const url = isEditing
      ? `https://workspace.optiven.co.ke/api/work-diary/update-activity/${editId}`
      : "https://workspace.optiven.co.ke/api/work-diary/insert-activity";
  
    // Combine date and time as local Date objects
    const startDateTimeLocal = new Date(`${formData.date}T${formData.startTime}`);
    const endDateTimeLocal = new Date(`${formData.date}T${formData.endTime}`);
  
    // Convert to ISO strings without timezone offset
    const startDateTimeString = `${formData.date} ${formData.startTime}`;
    const endDateTimeString = `${formData.date} ${formData.endTime}`;
  
    const dataToSend = {
      employee_id: userFromRedux.user_id,
      supervisor_id: formData.supervisorId,
      week_number: formData.weekNumber,
      date: formData.date, // 'YYYY-MM-DD'
      startTime: `${formData.startTime}:00`, // Ensure seconds are added
      endTime: `${formData.endTime}:00`,     // Ensure seconds are added
      focusArea: showNewFocusAreaFields ? "add_new" : formData.focusArea,
      newFocusAreaDescription: formData.newFocusArea,
      activityDescription: formData.activityDescription,
      comments: formData.comments,
    };
  
    try {
      const method = isEditing ? "put" : "post";
      const response = await axios({
        method,
        url,
        headers: { Authorization: `Bearer ${tokenFromRedux}` },
        data: dataToSend,
      });
  
      setSuccess(
        isEditing
          ? "Entry updated successfully!"
          : "Work diary entry submitted successfully!"
      );
      setShowSuccessPopup(true);
      clearForm();
  
      // Update entries state
      if (isEditing) {
        setEntries((prevEntries) =>
          prevEntries.map((entry) =>
            entry.id === editId
              ? { ...entry, ...response.data.updatedEntry }
              : entry
          )
        );
      } else {
        setEntries((prevEntries) => [...prevEntries, response.data.newEntry]);
      }
    } catch (error) {
      console.error("Error submitting work diary entry:", error);
      const errorMsg =
        error.response?.data?.message ||
        "Error submitting work diary entry. Please try again.";
      setErrors((prevErrors) => ({
        ...prevErrors,
        submit: errorMsg,
      }));
    } finally {
      setIsSubmitting(false);
    }
  };
  
  

  // Close the success popup
  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setSuccess(null);
  };

  // Add this utility function in your WorkDiaryEntry.jsx file
  const parseDateTime = (dateString, timeString) => {
    // dateString: 'YYYY-MM-DD'
    // timeString: 'HH:mm:ss' or 'HH:mm'
  
    // Ensure timeString includes seconds
    if (/^\d{2}:\d{2}$/.test(timeString)) { // Matches 'HH:mm'
      timeString = `${timeString}:00`;
    }
  
    const dateTimeString = `${dateString}T${timeString}`;
  
    const dateObj = new Date(dateTimeString);
  
    if (isNaN(dateObj.getTime())) {
      console.error(`Invalid Date created from ${dateTimeString}`);
      return null;
    }
  
    return dateObj;
  };
  
  
  

  return (
    <Sidebar>
      <div className="min-h-screen bg-gradient-to-br from-blue-100 to-indigo-200 py-12 px-4 sm:px-6 lg:px-8 relative overflow-hidden">
        <div className="max-w-5xl mx-auto">
          {/* Page Header */}
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-12"
          >
            <h1 className="text-5xl font-extrabold text-gray-900 sm:text-6xl md:text-7xl bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600 mb-4">
              Daily Work Diary
            </h1>
            <p className="mt-3 text-xl text-gray-600 sm:mt-5 max-w-2xl mx-auto">
              Track your daily tasks, boost productivity, and maintain a clear
              record of your work activities.
            </p>
          </motion.div>

          {/* Global Error Messages */}
          <AnimatePresence>
            {errors.submit && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="mb-6"
              >
                <div className="alert alert-error shadow-lg bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg">
                  <XCircle className="h-5 w-5 mr-2" />
                  <span className="font-medium">{errors.submit}</span>
                </div>
              </motion.div>
            )}
            {success && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="mb-6"
              >
                <div className="alert alert-success shadow-lg bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded-lg">
                  <CheckCircle className="h-5 w-5 mr-2" />
                  <span className="font-medium">{success}</span>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Success Popup */}
          <AnimatePresence>
            {showSuccessPopup && (
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50"
              >
                <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full">
                  <div className="flex items-center justify-center mb-4">
                    <CheckCircle className="h-12 w-12 text-green-500" />
                  </div>
                  <h3 className="text-lg font-semibold text-center mb-2">
                    Success!
                  </h3>
                  <p className="text-center text-gray-600">{success}</p>
                  <button
                    onClick={closeSuccessPopup}
                    className="mt-4 w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out"
                  >
                    Close
                  </button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Adjust Entries Button */}
          <div className="flex justify-center mb-8">
            <button
              onClick={() => setShowEntries(!showEntries)}
              className="btn btn-primary bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
            >
              {showEntries ? "Hide Entries" : "Adjust Entries"}
            </button>
          </div>

          {/* Entries Table */}
          {showEntries && (
            <div className="mb-12 bg-white rounded-lg shadow-xl p-6 overflow-hidden">
              <h2 className="text-2xl font-semibold text-gray-900 mb-6">
                Your Entries from the last 48 hours
              </h2>
              {loading.entries ? (
                <div className="text-center py-8">
                  <div className="spinner border-t-4 border-blue-500 border-solid rounded-full h-12 w-12 animate-spin mx-auto"></div>
                </div>
              ) : entries.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="table w-full">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Start Time
                        </th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          End Time
                        </th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Focus Area
                        </th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {entries.map((entry) => {
                      const entryStartLocal = parseDateTime(entry.date, entry.start_time);
                      const entryEndLocal = parseDateTime(entry.date, entry.end_time);
                      // Check if entry is within the last 48 hours
                      const fortyEightHoursAgo = subHours(new Date(), 48);
                      const isWithin48Hours = isAfter(entryEndLocal, fortyEightHoursAgo);
                      // Check for Invalid Dates
                      if (!entryStartLocal || !entryEndLocal) {
                        console.error('Invalid Date for entry:', entry);
                        return null; // Skip this entry or handle accordingly
                      }
                      return (
                        <tr key={entry.id} className="hover:bg-gray-50">
                          <td className="px-4 py-2 whitespace-nowrap">
                          {entryStartLocal ? entryStartLocal.toLocaleDateString() : 'Invalid Date'}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            {entryStartLocal.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            {entryEndLocal.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap">
                            {entry.focus_area}
                          </td><td className="px-4 py-2 whitespace-nowrap">
                            {isWithin48Hours ? (
                              <>
                                <button onClick={() => handleEdit(entry)} className="mr-2">
                                  <Edit className="h-5 w-5 text-blue-500" />
                                </button>
                                <button onClick={() => handleDelete(entry.id)}>
                                  <Trash className="h-5 w-5 text-red-500" />
                                </button>
                              </>
                            ) : (
                              <span className="text-gray-500 text-sm">Cannot Edit/Delete</span>
                            )}
                          </td>
                                            </tr>
                      );
                    })}
                      </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-gray-500 text-center py-8">
                  No entries found.
                </p>
              )}
            </div>
          )}

          {/* Edit Entry Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
              <div className="relative w-full max-w-lg mx-auto bg-white rounded-lg shadow-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold">
                    {isEditing
                      ? "Edit Work Diary Entry"
                      : "Add Work Diary Entry"}
                  </h3>
                  <button
                    onClick={clearForm}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <XCircle className="h-6 w-6" />
                  </button>
                </div>
                <form
                  onSubmit={handleSubmit}
                  className="space-y-4 max-h-[70vh] overflow-auto"
                >
                  <InputField
                    label="Date *"
                    name="date"
                    type="date"
                    value={formData.date}
                    onChange={handleInputChange}
                    icon={
                      <Calendar
                        className="h-5 w-5 text-indigo-500"
                        aria-hidden="true"
                      />
                    }
                    error={fieldErrors.date}
                  />
                  <InputField
                    label="Start Time *"
                    name="startTime"
                    type="time"
                    value={formData.startTime}
                    onChange={handleInputChange}
                    icon={<Clock className="h-5 w-5 text-indigo-500" />}
                    error={fieldErrors.startTime}
                  />
                  <InputField
                    label="End Time *"
                    name="endTime"
                    type="time"
                    value={formData.endTime}
                    onChange={handleInputChange}
                    icon={<Clock className="h-5 w-5 text-indigo-500" />}
                    error={fieldErrors.endTime}
                  />
                  <SelectField
                    label="Focus Area *"
                    name="focusArea"
                    value={formData.focusArea}
                    onChange={handleInputChange}
                    options={[
                      ...focusAreas.map((option) => ({
                        value: option.focus_area,
                        label: option.focus_area,
                      })),
                      { value: "Break", label: "Break" },
                      { value: "add_new", label: "Add New Focus Area" },
                    ]}
                    icon={<Briefcase className="h-5 w-5 text-indigo-500" />}
                    error={fieldErrors.focusArea}
                    // disabled={loading.focusAreas}
                  />
                  {showNewFocusAreaFields && (
                    <InputField
                      label="New Focus Area *"
                      name="newFocusArea"
                      value={formData.newFocusArea}
                      onChange={handleInputChange}
                      placeholder="Enter new focus area"
                      icon={<Briefcase className="h-5 w-5 text-indigo-500" />}
                      error={fieldErrors.newFocusArea}
                    />
                  )}
                  <TextAreaField
                    label="Activity Description *"
                    name="activityDescription"
                    value={formData.activityDescription}
                    onChange={handleInputChange}
                    rows={4}
                    placeholder="Describe the task you worked on..."
                    icon={<FileText className="h-5 w-5 text-indigo-500" />}
                    error={fieldErrors.activityDescription}
                  />
                  <TextAreaField
                    label="Comments (Optional)"
                    name="comments"
                    value={formData.comments}
                    onChange={handleInputChange}
                    rows={2}
                    placeholder="Additional comments"
                    icon={<MessageSquare className="h-5 w-5 text-indigo-500" />}
                  />
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={clearForm}
                      className="mr-2 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className={`px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md ${
                        isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Saving..." : "Save Changes"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {/* Main Form */}
          <motion.form
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            onSubmit={handleSubmit}
            className="mt-8 space-y-8 bg-white shadow-2xl rounded-lg p-8 backdrop-filter backdrop-blur-lg bg-opacity-90"
          >
            <div className="text-red-600 font-semibold mb-4">
              * Only entries from the past 48 hours are allowed.
            </div>
            {lastLoggedActivity && (
                <div className="mb-6 p-4 bg-blue-50 rounded-lg text-blue-700 border border-blue-200">
                  <strong className="font-medium">Last Logged Activity:</strong>{" "}
                  {isNaN(parseDateTime(lastLoggedActivity.date, lastLoggedActivity.end_time))
                    ? 'Invalid Date'
                    : parseDateTime(lastLoggedActivity.date, lastLoggedActivity.end_time).toLocaleString()}
                </div>
              )}
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <InputField
                label="Name"
                value={userFromRedux?.fullnames || "Loading..."}
                icon={
                  <User
                    className="h-5 w-5 text-indigo-500"
                    aria-hidden="true"
                  />
                }
                readOnly
              />
              <InputField
                label="Department"
                value={userFromRedux?.department || "Loading..."}
                icon={
                  <Briefcase
                    className="h-5 w-5 text-indigo-500"
                    aria-hidden="true"
                  />
                }
                readOnly
              />
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <InputField
                label="Supervisor"
                value={
                  supervisor
                    ? supervisor.fullnames
                    : errors.supervisor
                    ? errors.supervisor
                    : "No supervisor assigned"
                }
                icon={
                  <User
                    className="h-5 w-5 text-indigo-500"
                    aria-hidden="true"
                  />
                }
                readOnly
              />
              <InputField
                label="Week Number"
                name="weekNumber"
                type="number"
                value={formData.weekNumber}
                readOnly
                icon={
                  <Calendar
                    className="h-5 w-5 text-indigo-500"
                    aria-hidden="true"
                  />
                }
              />
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <InputField
                label="Date *"
                name="date"
                type="date"
                value={formData.date}
                onChange={handleInputChange}
                icon={
                  <Calendar
                    className="h-5 w-5 text-indigo-500"
                    aria-hidden="true"
                  />
                }
                error={fieldErrors.date}
              />
              <InputField
                label="Start Time *"
                name="startTime"
                type="time"
                value={formData.startTime}
                onChange={handleInputChange}
                icon={
                  <Clock
                    className="h-5 w-5 text-indigo-500"
                    aria-hidden="true"
                  />
                }
                error={fieldErrors.startTime}
              />
              <InputField
                label="End Time *"
                name="endTime"
                type="time"
                value={formData.endTime}
                onChange={handleInputChange}
                icon={
                  <Clock
                    className="h-5 w-5 text-indigo-500"
                    aria-hidden="true"
                  />
                }
                error={fieldErrors.endTime}
              />
              <SelectField
                label="Focus Area *"
                name="focusArea"
                value={formData.focusArea}
                onChange={handleInputChange}
                options={[
                  ...focusAreas.map((option) => ({
                    value: option.focus_area,
                    label: option.focus_area,
                  })),
                  { value: "Break", label: "Break" },
                  { value: "add_new", label: "Add New Focus Area" },
                ]}
                icon={
                  <Briefcase
                    className="h-5 w-5 text-indigo-500"
                    aria-hidden="true"
                  />
                }
                error={fieldErrors.focusArea}
                disabled={loading.focusAreas}
              />
              {showNewFocusAreaFields && (
                <InputField
                  label="New Focus Area *"
                  name="newFocusArea"
                  value={formData.newFocusArea}
                  onChange={handleInputChange}
                  placeholder="Enter new focus area"
                  icon={
                    <Briefcase
                      className="h-5 w-5 text-indigo-500"
                      aria-hidden="true"
                    />
                  }
                  error={fieldErrors.newFocusArea}
                />
              )}
            </div>
            <TextAreaField
              label="Activity Description *"
              name="activityDescription"
              value={formData.activityDescription}
              onChange={handleInputChange}
              rows={4}
              placeholder="Describe the task you worked on..."
              icon={
                <FileText
                  className="h-5 w-5 text-indigo-500"
                  aria-hidden="true"
                />
              }
              error={fieldErrors.activityDescription}
            />

            <TextAreaField
              label="Comments (Optional)"
              name="comments"
              value={formData.comments}
              onChange={handleInputChange}
              rows={2}
              placeholder="Additional comments"
              icon={
                <MessageSquare
                  className="h-5 w-5 text-indigo-500"
                  aria-hidden="true"
                />
              }
            />

            <div className="flex items-center justify-center">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                disabled={isSubmitting}
                className={`px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md ${
                  isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </motion.button>
            </div>
          </motion.form>
        </div>
      </div>
    </Sidebar>
  );
};

// Utility InputField component for handling text inputs
const InputField = ({
  label,
  name,
  type = "text",
  value,
  onChange,
  icon,
  readOnly = false,
  placeholder = "",
  error = "",
}) => (
  <div className="form-control">
    <label className="label">
      <span className="label-text font-medium text-gray-700">{label}</span>
      {error && <span className="label-text-alt text-red-500">{error}</span>}
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {icon}
      </div>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        placeholder={placeholder}
        className={`input input-bordered w-full pl-10 py-2 bg-gray-50 border ${
          error ? "border-red-500" : "border-gray-300"
        } text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 ${
          readOnly ? "bg-gray-100" : ""
        }`}
      />
    </div>
  </div>
);

// Utility SelectField component for handling dropdowns
const SelectField = ({
  label,
  name,
  value,
  onChange,
  options,
  icon,
  error = "",
  disabled = false,
}) => (
  <div className="form-control">
    <label className="label">
      <span className="label-text font-medium text-gray-700">{label}</span>
      {error && <span className="label-text-alt text-red-500">{error}</span>}
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {icon}
      </div>
      <select
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={`select select-bordered w-full pl-10 py-2 bg-gray-50 border ${
          error ? "border-red-500" : "border-gray-300"
        } text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 ${
          disabled ? "bg-gray-100 cursor-not-allowed" : ""
        }`}
      >
        <option value="">Select a focus area</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  </div>
);

// Utility TextAreaField component for handling text areas
const TextAreaField = ({
  label,
  name,
  value,
  onChange,
  rows,
  placeholder,
  icon,
  error = "",
}) => (
  <div className="form-control">
    <label className="label">
      <span className="label-text font-medium text-gray-700">{label}</span>
      {error && <span className="label-text-alt text-red-500">{error}</span>}
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 pt-3 flex items-start pointer-events-none">
        {icon}
      </div>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        rows={rows}
        placeholder={placeholder}
        className={`textarea textarea-bordered w-full pl-10 py-2 bg-gray-50 border ${
          error ? "border-red-500" : "border-gray-300"
        } text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500`}
      />
    </div>
  </div>
);

// Error handling function
const handleErrors = (error, setError) => {
  if (error.response && error.response.data) {
    const errorMsg = error.response.data.message || "An error occurred.";
    setError(errorMsg);
  } else {
    setError("An unexpected error occurred. Please try again.");
  }
};

export default WorkDiaryEntry;
