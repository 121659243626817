import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  FaHome,
  FaTasks,
  FaCalendarAlt,
  FaChartBar,
  FaUserShield,
} from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Sidebar = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);
  const [isSupervisor, setIsSupervisor] = useState(false);

  // AccessRole for determining HR Admin access
  const accessRole = useSelector(
    (state) => state.user.user?.Accessrole || ""
  ).trim();

  useEffect(() => {
    const checkSupervisorStatus = async () => {
      if (user && user.user_id) {
        try {
          const response = await axios.get(
            `https://workspace.optiven.co.ke/api/work-diary/is-supervisor/${user.user_id}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          // Check if the user is a supervisor or has the workAdmin role
          setIsSupervisor(response.data.isSupervisor);
        } catch (error) {
          console.error("Error checking supervisor status:", error);
          setIsSupervisor(false);
        }
      }
    };
    checkSupervisorStatus();
  }, [user, token]);

  // Define menu items
  const menuItems = [
    {
      to: "/dashboard-work-diary",
      label: "Dashboard",
      icon: <FaHome className="text-2xl text-green-400" />,
      access: true, // Accessible by all
    },
    {
      to: "/work-diary-entry",
      label: "Work Diary Entry",
      icon: <FaTasks className="text-2xl text-green-400" />,
      access: true, // Accessible by all
    },
    {
      to: "/calendar",
      label: "Calendar",
      icon: <FaCalendarAlt className="text-2xl text-green-400" />,
      access: true, // Accessible by all
    },
    {
      to: "/reports",
      label: "Reports",
      icon: <FaChartBar className="text-2xl text-green-400" />,
      access: true, // Accessible by all
    },
    ...(isSupervisor
      ? [
          {
            to: "/supervisor-dashboard",
            label: "Supervisor Dashboard",
            icon: <FaUserShield className="text-2xl text-yellow-400" />,
            access: true, // Accessible only if the user is a supervisor
          },
        ]
      : []),
    ...(accessRole.includes("workDiaryAdmin") // <-- Checking if workDiaryAdmin exists in the Accessrole string
      ? [
          {
            to: "/hr-admin-dashboard",
            label: "HR Admin Dashboard",
            icon: <RiAdminFill className="text-3xl text-purple-500" />,
            access: true,
          },
        ]
      : []),
  ];

  return (
    <div className="drawer">
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">{children}</div>

      <div className="drawer-side">
        <label htmlFor="my-drawer" className="drawer-overlay"></label>
        <ul className="menu p-4 w-80 bg-gray-900 text-white space-y-2">
          {/* Render the menu items */}
          {menuItems
            .filter((item) => item.access) // Only render if access is true
            .map((item, index) => (
              <li key={index}>
                <Link
                  to={item.to}
                  className="flex items-center space-x-3 py-4 hover:bg-gray-700"
                >
                  {item.icon}
                  <span className="font-medium">{item.label}</span>
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
