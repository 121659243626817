import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { motion } from 'framer-motion';
import { ChevronRight, Calendar, Clock, List, BarChart } from 'lucide-react';
import { Link } from 'react-router-dom';
const WorkDiary = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <Sidebar>
      <section className="min-h-screen grid grid-cols-1 lg:grid-cols-2 bg-gradient-to-b from-blue-100 via-blue-200 to-blue-300 text-gray-800 overflow-hidden relative">
        {/* Text Section */}
        <motion.div 
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: isLoaded ? 1 : 0, x: isLoaded ? 0 : -50 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="flex flex-col justify-center px-6 lg:px-16 py-20 space-y-8 text-center lg:text-left relative z-10"
        >
          <h1 className="text-5xl lg:text-6xl xl:text-7xl font-black leading-tight tracking-tighter">
            Your
            <span className="block text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
              Daily Work Register
            </span>
          </h1>
          <p className="text-xl lg:text-2xl text-gray-600 max-w-2xl mx-auto lg:mx-0 leading-relaxed">
              Revolutionize your productivity with our cutting-edge work register. <span className="font-bold text-gray-800">Don't forget to click the <span className="text-blue-600 underline">sidebar button</span> on the top left</span> of your screen to access more options and unlock all features!
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-center lg:justify-start space-y-4 sm:space-y-0 sm:space-x-4">
            <Link to="/work-diary-entry">
                <motion.button 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="px-8 py-4 bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-bold rounded-full shadow-lg hover:shadow-xl transition duration-300 ease-in-out flex items-center"
                >
                  Get Started <ChevronRight className="ml-2" />
                </motion.button>
              </Link>
          </div>
        </motion.div>

        {/* Feature Highlights */}
        <motion.div 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: isLoaded ? 1 : 0, y: isLoaded ? 0 : 50 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="flex flex-col justify-center lg:h-screen"
        >
          <div className="grid grid-cols-2 gap-6 p-8 bg-white/30 backdrop-blur-md rounded-tl-3xl lg:rounded-l-3xl shadow-lg">
            <FeatureCard icon={Calendar} title="Date Tracking" description="Effortlessly log your daily activities" />
            <FeatureCard icon={Clock} title="Time Management" description="Visualize and optimize your time usage" />
            <FeatureCard icon={List} title="Task Organization" description="Categorize and prioritize your work" />
            <FeatureCard icon={BarChart} title="Progress Insights" description="Gain valuable insights into your productivity" />
          </div>
        </motion.div>

        {/* Subtle Background Animation */}
        <div className="absolute inset-0 z-0 opacity-30">
          <div className="absolute top-0 -left-4 w-72 h-72 bg-blue-300 rounded-full mix-blend-multiply filter blur-xl animate-blob"></div>
          <div className="absolute top-0 -right-4 w-72 h-72 bg-indigo-300 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-2000"></div>
          <div className="absolute -bottom-8 left-20 w-72 h-72 bg-blue-200 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-4000"></div>
        </div>
      </section>
    </Sidebar>
  );
};

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div 
    whileHover={{ scale: 1.05, backgroundColor: 'rgba(255, 255, 255, 0.4)' }}
    className="p-6 rounded-xl backdrop-blur-sm bg-white/20 flex flex-col items-center text-center transition duration-300 ease-in-out shadow-md"
  >
    <Icon className="w-12 h-12 mb-4 text-blue-600" />
    <h3 className="text-xl font-bold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

export default WorkDiary;