import Sidebar from "./Sidebar";
import axios from "axios";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import React, { useState, useMemo, useEffect } from "react";
import { FaFileExcel, FaFilePdf, FaFileCsv, FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import "jspdf-autotable";
import { motion } from "framer-motion";

const ReportsSection = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCriteria, setFilterCriteria] = useState({
    startDate: "",
    endDate: "",
    focusArea: "",
  });
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);

  // Now use the variables in useEffect hooks
  useEffect(() => {
    const fetchActivities = async () => {
      setLoading(true);
      try {
        const params = {
          startDate: filterCriteria.startDate || "",
          endDate: filterCriteria.endDate || "",
        };
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/work-diary/activities/user-reports/${user.user_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params,
          }
        );
        setActivities(Array.isArray(response.data) ? response.data : []);
      } catch (err) {
        console.error("Error fetching activities:", err);
        setError("Failed to fetch activities. Please try again later.");
        setActivities([]); // Ensure activities is an array even on error
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, [token, user.user_id, filterCriteria.startDate, filterCriteria.endDate]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, filterCriteria]);

  // Declare filteredActivities before using it
  const filteredActivities = useMemo(() => {
    if (!Array.isArray(activities)) return [];

    return activities.filter((activity) => {
      const matchesSearch =
        activity.activity_description
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        activity.focus_area.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesDateRange =
        (!filterCriteria.startDate ||
          new Date(activity.date) >= new Date(filterCriteria.startDate)) &&
        (!filterCriteria.endDate ||
          new Date(activity.date) <= new Date(filterCriteria.endDate));

      const matchesFocusArea =
        !filterCriteria.focusArea ||
        activity.focus_area === filterCriteria.focusArea;

      return matchesSearch && matchesDateRange && matchesFocusArea;
    });
  }, [activities, searchTerm, filterCriteria]);

  // Declare sortedActivities before using it
  const sortedActivities = useMemo(() => {
    let sortableItems = [...filteredActivities];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredActivities, sortConfig]);

  // Declare currentActivities before using it
  const currentActivities = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return sortedActivities.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, itemsPerPage, sortedActivities]);

  useEffect(() => {
    const totalPages = Math.ceil(sortedActivities.length / itemsPerPage);
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1);
    }
  }, [sortedActivities.length, currentPage, itemsPerPage]);

  const handleExport = (format) => {
    if (format === "csv") {
      exportToCSV();
    } else if (format === "excel") {
      exportToExcel();
    } else if (format === "pdf") {
      exportToPDF();
    }
  };

  const exportToCSV = () => {
    const csvRows = [
      ["Date", "Focus Area", "Description", "Start Time", "End Time"],
      ...sortedActivities.map((activity) => [
        format(new Date(activity.date), "yyyy-MM-dd"),
        activity.focus_area,
        activity.activity_description,
        activity.start_time,
        activity.end_time,
      ]),
    ];

    const csvContent = csvRows.map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "my_reports.csv");
  };

  const exportToExcel = () => {
    const worksheetData = sortedActivities.map((activity) => ({
      Date: format(new Date(activity.date), "yyyy-MM-dd"),
      "Focus Area": activity.focus_area,
      Description: activity.activity_description,
      "Start Time": activity.start_time,
      "End Time": activity.end_time,
    }));

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "My Reports");
    XLSX.writeFile(workbook, "my_reports.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("My Reports", 20, 10);
    doc.autoTable({
      head: [["Date", "Focus Area", "Description", "Start Time", "End Time"]],
      body: sortedActivities.map((activity) => [
        format(new Date(activity.date), "yyyy-MM-dd"),
        activity.focus_area,
        activity.activity_description,
        activity.start_time,
        activity.end_time,
      ]),
    });
    doc.save("my_reports.pdf");
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterCriteria((prev) => ({ ...prev, [name]: value }));
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const LoadingSpinner = () => (
    <div className="flex justify-center items-center h-screen bg-gradient-to-br from-green-100 to-teal-200">
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
        className="h-16 w-16 border-t-4 border-b-4 border-green-500 rounded-full"
      />
    </div>
  );

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-red-500 text-center">{error}</div>;

  // Get unique focus areas for filter options
  const focusAreas = Array.from(new Set(activities.map((a) => a.focus_area)));

  return (
    <Sidebar>
      <div className="p-6 bg-gradient-to-br from-green-50 to-teal-100 min-h-screen">
        <h1 className="text-4xl font-bold mb-6">My Reports</h1>

        <div className="mb-6 flex flex-wrap items-center justify-between gap-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={() => handleExport("excel")}
              className="inline-flex items-center px-4 py-2 bg-green-500 text-white rounded-md shadow hover:bg-green-600 transition-colors duration-200"
            >
              <FaFileExcel className="mr-2" />
              Excel
            </button>
            <button
              onClick={() => handleExport("pdf")}
              className="inline-flex items-center px-4 py-2 bg-red-500 text-white rounded-md shadow hover:bg-red-600 transition-colors duration-200"
            >
              <FaFilePdf className="mr-2" />
              PDF
            </button>
            <button
              onClick={() => handleExport("csv")}
              className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600 transition-colors duration-200"
            >
              <FaFileCsv className="mr-2" />
              CSV
            </button>
          </div>
          <div className="flex items-center space-x-2">
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                className="input input-bordered pl-10"
                value={searchTerm}
                onChange={handleSearch}
              />
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            <select
              name="focusArea"
              value={filterCriteria.focusArea}
              onChange={handleFilterChange}
              className="select select-bordered"
            >
              <option value="">All Focus Areas</option>
              {focusAreas.map((area) => (
                <option key={area} value={area}>
                  {area}
                </option>
              ))}
            </select>
            <input
              type="date"
              name="startDate"
              value={filterCriteria.startDate}
              onChange={handleFilterChange}
              className="input input-bordered"
              max={filterCriteria.endDate || undefined}
            />
            <input
              type="date"
              name="endDate"
              value={filterCriteria.endDate}
              onChange={handleFilterChange}
              className="input input-bordered"
              min={filterCriteria.startDate || undefined}
            />
          </div>
        </div>

        <div className="bg-white shadow-md rounded-lg overflow-x-auto">
          {currentActivities.length === 0 ? (
            <div className="text-center py-4">
              No activities found for the selected criteria.
            </div>
          ) : (
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th
                    className="py-3 px-6 text-left cursor-pointer"
                    onClick={() => handleSort("date")}
                  >
                    Date{" "}
                    {sortConfig.key === "date" &&
                      (sortConfig.direction === "ascending" ? "▲" : "▼")}
                  </th>
                  <th
                    className="py-3 px-6 text-left cursor-pointer"
                    onClick={() => handleSort("focus_area")}
                  >
                    Focus Area{" "}
                    {sortConfig.key === "focus_area" &&
                      (sortConfig.direction === "ascending" ? "▲" : "▼")}
                  </th>
                  <th className="py-3 px-6 text-left">Description</th>
                  <th
                    className="py-3 px-6 text-left cursor-pointer"
                    onClick={() => handleSort("start_time")}
                  >
                    Start Time{" "}
                    {sortConfig.key === "start_time" &&
                      (sortConfig.direction === "ascending" ? "▲" : "▼")}
                  </th>
                  <th
                    className="py-3 px-6 text-left cursor-pointer"
                    onClick={() => handleSort("end_time")}
                  >
                    End Time{" "}
                    {sortConfig.key === "end_time" &&
                      (sortConfig.direction === "ascending" ? "▲" : "▼")}
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {currentActivities.map((activity, index) => (
                  <tr
                    key={index}
                    className="border-b border-gray-200 hover:bg-gray-100 transition-colors duration-200"
                  >
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      {format(new Date(activity.date), "yyyy-MM-dd")}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {activity.focus_area}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {activity.activity_description}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {activity.start_time}
                    </td>
                    <td className="py-3 px-6 text-left">{activity.end_time}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {currentActivities.length > 0 && (
          <div className="mt-6 flex justify-center">
            <ul className="flex space-x-2">
              {Array.from(
                {
                  length: Math.ceil(sortedActivities.length / itemsPerPage),
                },
                (_, i) => (
                  <li key={i}>
                    <button
                      onClick={() => paginate(i + 1)}
                      className={`px-3 py-1 rounded ${
                        currentPage === i + 1
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200"
                      }`}
                    >
                      {i + 1}
                    </button>
                  </li>
                )
              )}
            </ul>
          </div>
        )}
      </div>
    </Sidebar>
  );
};

export default ReportsSection;
