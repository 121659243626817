import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const ViewCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [countryFilter, setCountryFilter] = useState("");
  const [genderFilter, setGenderFilter] = useState("");

  // Fetch all customer data from the API
  const fetchCustomers = async () => {
    try {
      const response = await axios.get(
        `https://workspace.optiven.co.ke/api/loyalty`
      );
      const { customers } = response.data;
      setCustomers(customers);
      setFilteredCustomers(customers);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    filterCustomers();
  }, [countryFilter, genderFilter, customers]);

  const filterCustomers = () => {
    const filteredData = customers.filter((customer) => {
      const matchesCountry = countryFilter
        ? customer.country_of_residence === countryFilter
        : true;
      const matchesGender = genderFilter
        ? customer.gender === genderFilter
        : true;
      return matchesCountry && matchesGender;
    });
    setFilteredCustomers(filteredData);
  };

  // Export filtered data to Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredCustomers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "filtered_customers.xlsx");
  };

  // Unique country list (uppercase) and gender list excluding 'N/A'
  const uniqueCountries = [
    ...new Set(
      customers
        .map(
          (c) => c.country_of_residence && c.country_of_residence.toUpperCase()
        )
        .filter(Boolean)
    ),
  ];
  const uniqueGenders = [
    ...new Set(customers.map((c) => c.gender).filter((g) => g !== "N/A")),
  ];

  return (
    <Sidebar>
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="container mx-auto">
          <div className="flex flex-col w-full items-center">
            <div className="flex flex-col md:flex-row gap-4 my-4">
              <select
                value={countryFilter}
                onChange={(e) => setCountryFilter(e.target.value)}
                className="select select-bordered"
              >
                <option value="">Filter by Country</option>
                {uniqueCountries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              <select
                value={genderFilter}
                onChange={(e) => setGenderFilter(e.target.value)}
                className="select select-bordered"
              >
                <option value="">Filter by Gender</option>
                {uniqueGenders.map((gender) => (
                  <option key={gender} value={gender}>
                    {gender}
                  </option>
                ))}
              </select>
              <button className="btn" onClick={exportToExcel}>
                Download Excel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default ViewCustomers;
