import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../components/sidebar/Sidebar";

const SystemSurvey = () => {
  const [step, setStep] = useState(1);
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    program: "",
    user: "", // user field to hold selected user id and name
    understanding: "",
    knowHowToUse: "",
    usefulness: "",
    experience: "",
    userFriendliness: "",
    easeOfUse: "",
    frustrations: "",
    expectations: "",
    helpfulness: "",
    importantElements: "",
    desiredChanges: "",
    additionalFeatures: "",
    difficulties: "",
    usageFrequency: "",
    mostUsedFeatures: "",
    navigationEase: "",
    findability: "",
    taskChallenges: "",
    learningSpeed: "",
    informationClarity: "",
    screenGoal: "",
    interfaceRating: "",
    menuClarity: "",
    elementPosition: "",
    designSimplification: "",
    removals: "",
    layoutOrganization: "",
    screenAdditions: "",
    navigationRating: "",
    pageLikeability: "",
    aestheticsRating: "",
    recommendationLikelihood: "",
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch("https://workspace.optiven.co.ke/api/users");
      const data = await response.json();
      const userOptions = data.map((user) => ({
        value: user.user_id,
        label: user.fullnames,
      }));
      setUsers([
        { value: "", label: "Select a user" },
        ...userOptions,
      ]);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const feedbackData = {
        name: users.find(user => user.value === formData.user)?.label || "",
        ...formData,
      };

      const response = await fetch("https://workspace.optiven.co.ke/api/survey", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(feedbackData),
      });

      if (response.ok) {
        toast.success("Feedback submitted successfully!");
        setFormData({
          program: "",
          user: "",
          understanding: "",
          knowHowToUse: "",
          usefulness: "",
          experience: "",
          userFriendliness: "",
          easeOfUse: "",
          frustrations: "",
          expectations: "",
          helpfulness: "",
          importantElements: "",
          desiredChanges: "",
          additionalFeatures: "",
          difficulties: "",
          usageFrequency: "",
          mostUsedFeatures: "",
          navigationEase: "",
          findability: "",
          taskChallenges: "",
          learningSpeed: "",
          informationClarity: "",
          screenGoal: "",
          interfaceRating: "",
          menuClarity: "",
          elementPosition: "",
          designSimplification: "",
          removals: "",
          layoutOrganization: "",
          screenAdditions: "",
          navigationRating: "",
          pageLikeability: "",
          aestheticsRating: "",
          recommendationLikelihood: "",
        });
        setStep(1); 
      } else {
        toast.error("Failed to submit feedback.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting feedback.");
    }
  };
  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      user: selectedOption ? selectedOption.value : "",
    });
  };

  const nextStep = () => {
    if (!validateStepFields(step)) {
      toast.error("Please fill out all required fields before proceeding.");
      return;
    }
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  // Validate fields for the current step
  const validateStepFields = (currentStep) => {
    switch (currentStep) {
      case 1:
        return (
          formData.program &&
          formData.understanding &&
          formData.knowHowToUse &&
          formData.usefulness &&
          formData.experience
        );
      case 2:
        return (
          formData.userFriendliness &&
          formData.easeOfUse &&
          formData.frustrations &&
          formData.expectations
        );
      case 3:
        return (
          formData.helpfulness &&
          formData.importantElements &&
          formData.desiredChanges &&
          formData.additionalFeatures &&
          formData.difficulties
        );
      case 4:
        return (
          formData.usageFrequency &&
          formData.mostUsedFeatures &&
          formData.navigationEase &&
          formData.findability
        );
      case 5:
        return (
          formData.taskChallenges &&
          formData.learningSpeed &&
          formData.informationClarity &&
          formData.screenGoal
        );
      case 6:
        return (
          formData.interfaceRating &&
          formData.menuClarity &&
          formData.elementPosition &&
          formData.designSimplification
        );
      case 7:
        return (
          formData.removals &&
          formData.layoutOrganization &&
          formData.screenAdditions &&
          formData.navigationRating
        );
      case 8:
        return (
          formData.pageLikeability &&
          formData.aestheticsRating &&
          formData.recommendationLikelihood
        );
      default:
        return false;
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">
              General Usability and Experience
            </h2>

            <label className="block mb-2">Select User</label>
            <Select
              value={users.find((user) => user.value === formData.user)}
              onChange={handleSelectChange}
              options={users}
              className="block w-full p-2 mb-4 border rounded"
              placeholder="Select a user"
              isSearchable
              required
            />

            <label className="block mb-2">Select the system</label>
            <select
              name="program"
              value={formData.program}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select a system</option>
              <option value="Optiven Homes">Optiven Homes</option>
              <option value="Logistics">Logistics</option>
              <option value="Foundation">Foundation</option>
              <option value="Workplan Automation">Workplan Automation</option>
              <option value="Value Addition">Value Addition</option>
              <option value="Visitors Management">Visitors Management</option>
              <option value="Performance">Performance</option>
              <option value="Conference">Conference</option>
              <option value="Feedback">Feedback</option>
              <option value="Amani">Amani Chatbot</option>
            </select>

            <label className="block mb-2">
              I understand what the product does.
            </label>
            <select
              name="understanding"
              value={formData.understanding}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Strongly Agree">Strongly Agree</option>
              <option value="Agree">Agree</option>
              <option value="Neutral">Neutral</option>
              <option value="Disagree">Disagree</option>
              <option value="Strongly Disagree">Strongly Disagree</option>
            </select>

            <label className="block mb-2">
              I know how to use the product.
            </label>
            <select
              name="knowHowToUse"
              value={formData.knowHowToUse}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Strongly Agree">Strongly Agree</option>
              <option value="Agree">Agree</option>
              <option value="Neutral">Neutral</option>
              <option value="Disagree">Disagree</option>
              <option value="Strongly Disagree">Strongly Disagree</option>
            </select>

            <label className="block mb-2">
              How useful is our product to you?
            </label>
            <input
              type="text"
              name="usefulness"
              value={formData.usefulness}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            />

            <label className="block mb-2">
              Tell us about your experience using the product.
            </label>
            <textarea
              name="experience"
              value={formData.experience}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            ></textarea>

            <button
              type="button"
              className="w-full py-2 mb-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={nextStep}
            >
              Next
            </button>
          </div>
        );
      case 2:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">
              General Usability and Experience (Continued)
            </h2>
            <label className="block mb-2">
              How would you rate the user-friendliness of the interface?
            </label>
            <select
              name="userFriendliness"
              value={formData.userFriendliness}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Very User-Friendly">Very User-Friendly</option>
              <option value="User-Friendly">User-Friendly</option>
              <option value="Neutral">Neutral</option>
              <option value="Not User-Friendly">Not User-Friendly</option>
              <option value="Very Difficult to Use">
                Very Difficult to Use
              </option>
            </select>

            <label className="block mb-2">
              Overall, how easy to use do you find the product?
            </label>
            <select
              name="easeOfUse"
              value={formData.easeOfUse}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Very Easy">Very Easy</option>
              <option value="Easy">Easy</option>
              <option value="Neutral">Neutral</option>
              <option value="Difficult">Difficult</option>
              <option value="Very Difficult">Very Difficult</option>
            </select>

            <label className="block mb-2">
              What do you find most frustrating about the product?
            </label>
            <textarea
              name="frustrations"
              value={formData.frustrations}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            ></textarea>

            <label className="block mb-2">
              To what extent does this product match your expectations?
            </label>
            <input
              type="text"
              name="expectations"
              value={formData.expectations}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            />

            <button
              type="button"
              className="w-full py-2 mb-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={nextStep}
            >
              Next
            </button>
            <button
              type="button"
              className="w-full py-2 mb-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={prevStep}
            >
              Previous
            </button>
          </div>
        );
      case 3:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Design and Aesthetics</h2>
            <label className="block mb-2">
              How do you think this product can help you?
            </label>
            <input
              type="text"
              name="helpfulness"
              value={formData.helpfulness}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            />

            <label className="block mb-2">
              What elements of this product are the most/least important to
              you?
            </label>
            <textarea
              name="importantElements"
              value={formData.importantElements}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            ></textarea>

            <label className="block mb-2">
              What would you like to change about this product?
            </label>
            <textarea
              name="desiredChanges"
              value={formData.desiredChanges}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            ></textarea>

            <label className="block mb-2">
              Is there anything more you would like us to add to this
              product?
            </label>
            <textarea
              name="additionalFeatures"
              value={formData.additionalFeatures}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            ></textarea>
            <label className="block mb-2">
              Were there any specific tasks that you found challenging?
            </label>
            <textarea
              name="difficulties"
              value={formData.difficulties}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            ></textarea>

            <button
              type="button"
              className="w-full py-2 mb-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={nextStep}
            >
              Next
            </button>
            <button
              type="button"
              className="w-full py-2 mb-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={prevStep}
            >
              Previous
            </button>
          </div>
        );
      case 4:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">
              Engagement and Usage Frequency
            </h2>
            <label className="block mb-2">
              How often do you use our product?
            </label>
            <select
              name="usageFrequency"
              value={formData.usageFrequency}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
              <option value="Rarely">Rarely</option>
              <option value="Never">Never</option>
            </select>

            <label className="block mb-2">
              What features do you use the most on the app?
            </label>
            <textarea
              name="mostUsedFeatures"
              value={formData.mostUsedFeatures}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            ></textarea>

            <label className="block mb-2">
              Did you find the navigation menu intuitive and easy to
              understand?
            </label>
            <select
              name="navigationEase"
              value={formData.navigationEase}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Very Intuitive">Very Intuitive</option>
              <option value="Intuitive">Intuitive</option>
              <option value="Neutral">Neutral</option>
              <option value="Difficult">Difficult</option>
              <option value="Very Difficult">Very Difficult</option>
            </select>

            <label className="block mb-2">
              Were you able to quickly find what you were looking for?
            </label>
            <select
              name="findability"
              value={formData.findability}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Always">Always</option>
              <option value="Often">Often</option>
              <option value="Sometimes">Sometimes</option>
              <option value="Rarely">Rarely</option>
              <option value="Never">Never</option>
            </select>

            <button
              type="button"
              className="w-full py-2 mb-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={nextStep}
            >
              Next
            </button>
            <button
              type="button"
              className="w-full py-2 mb-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={prevStep}
            >
              Previous
            </button>
          </div>
        );
      case 5:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">
              Design and Aesthetics (Continued)
            </h2>
            <label className="block mb-2">
              Were there any specific tasks that you found challenging?
            </label>
            <textarea
              name="taskChallenges"
              value={formData.taskChallenges}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            ></textarea>

            <label className="block mb-2">
              How quickly were you able to learn how to use the product?
            </label>
            <input
              type="text"
              name="learningSpeed"
              value={formData.learningSpeed}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            />

            <label className="block mb-2">
              Were instructions and information provided clear and easy to
              understand?
            </label>
            <select
              name="informationClarity"
              value={formData.informationClarity}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Very Clear">Very Clear</option>
              <option value="Clear">Clear</option>
              <option value="Neutral">Neutral</option>
              <option value="Unclear">Unclear</option>
              <option value="Very Unclear">Very Unclear</option>
            </select>

            <label className="block mb-2">
              What are you trying to accomplish on this screen?
            </label>
            <input
              type="text"
              name="screenGoal"
              value={formData.screenGoal}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            />

            <button
              type="button"
              className="w-full py-2 mb-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={nextStep}
            >
              Next
            </button>
            <button
              type="button"
              className="w-full py-2 mb-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={prevStep}
            >
              Previous
            </button>
          </div>
        );
      case 6:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">User Satisfaction Survey</h2>
            <label className="block mb-2">
              How would you rate the interface design of the website/app?
            </label>
            <select
              name="interfaceRating"
              value={formData.interfaceRating}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Average">Average</option>
              <option value="Poor">Poor</option>
              <option value="Very Poor">Very Poor</option>
            </select>

            <label className="block mb-2">Do the menu items make sense?</label>
            <select
              name="menuClarity"
              value={formData.menuClarity}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>

            <label className="block mb-2">
              Is the position of this element appropriate?
            </label>
            <select
              name="elementPosition"
              value={formData.elementPosition}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>

            <label className="block mb-2">
              What can we do to simplify this design?
            </label>
            <textarea
              name="designSimplification"
              value={formData.designSimplification}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            ></textarea>

            <button
              type="button"
              className="w-full py-2 mb-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={nextStep}
            >
              Next
            </button>
            <button
              type="button"
              className="w-full py-2 mb-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={prevStep}
            >
              Previous
            </button>
          </div>
        );
      case 7:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">
              User Satisfaction Survey (Continued)
            </h2>
            <label className="block mb-2">
              Is there anything you would like us to remove?
            </label>
            <textarea
              name="removals"
              value={formData.removals}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            ></textarea>

            <label className="block mb-2">
              Does the screen layout look organized to you?
            </label>
            <select
              name="layoutOrganization"
              value={formData.layoutOrganization}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>

            <label className="block mb-2">
              Is there anything you would like us to add to this screen?
            </label>
            <textarea
              name="screenAdditions"
              value={formData.screenAdditions}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            ></textarea>

            <label className="block mb-2">
              How would you like to rate the overall navigation experience?
            </label>
            <select
              name="navigationRating"
              value={formData.navigationRating}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Average">Average</option>
              <option value="Poor">Poor</option>
              <option value="Very Poor">Very Poor</option>
            </select>

            <button
              type="button"
              className="w-full py-2 mb-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={nextStep}
            >
              Next
            </button>
            <button
              type="button"
              className="w-full py-2 mb-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={prevStep}
            >
              Previous
            </button>
          </div>
        );
      case 8:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">
              User Satisfaction Survey (Final)
            </h2>
            <label className="block mb-2">
              Do you like how the page looks?
            </label>
            <select
              name="pageLikeability"
              value={formData.pageLikeability}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>

            <label className="block mb-2">
              How would you rate the overall aesthetics of the product?
            </label>
            <select
              name="aestheticsRating"
              value={formData.aestheticsRating}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Average">Average</option>
              <option value="Poor">Poor</option>
              <option value="Very Poor">Very Poor</option>
            </select>

            <label className="block mb-2">
              Considering that you’ve used our interface extensively, how
              likely are you to recommend it to your friends and colleagues?
            </label>
            <select
              name="recommendationLikelihood"
              value={formData.recommendationLikelihood}
              onChange={handleChange}
              className="block w-full p-2 mb-4 border rounded"
              required
            >
              <option value="">Select an option</option>
              <option value="Very Likely">Very Likely</option>
              <option value="Likely">Likely</option>
              <option value="Neutral">Neutral</option>
              <option value="Unlikely">Unlikely</option>
              <option value="Very Unlikely">Very Unlikely</option>
            </select>

            <button
              type="submit"
              className="w-full py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              Submit
            </button>
            <button
              type="button"
              className="w-full py-2 mb-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={prevStep}
            >
              Previous
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Sidebar>
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
      <form
        className="w-full max-w-lg p-6 bg-white rounded-lg shadow-md"
        onSubmit={handleSubmit}
      >
        {renderStep()}
      </form>
    </div>
    </Sidebar>
  );
};

export default SystemSurvey;
