import React from "react";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";

const LoyaltyHome = () => {
  return (
    <Sidebar>
      <div
        className="hero min-h-screen"
        style={{
          backgroundImage:
            "url(https://images.pexels.com/photos/301614/pexels-photo-301614.jpeg)",
        }}
      >
        <div className="hero-overlay bg-opacity-60"></div>
        <div className="hero-content text-neutral-content text-center">
          <div className="max-w-md">
            <h1 className="mb-5 text-5xl font-bold">
              Empower Loyalty, <br />
              Cultivate Growth.
            </h1>
            <p className="mb-5">
              Effortlessly manage your loyalty program, <br />
              reward your customers, and watch your business thrive.
            </p>
            <Link to={"/view-customers"} className="btn btn-primary text-white">
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default LoyaltyHome;
