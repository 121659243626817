import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-search";
import vipingo from "./images/vipingo.png";
import "leaflet-search/dist/leaflet-search.min.css";
import pin from "../OG/images/pin.png";

const MapComponent = ({ geojsonData }) => {
  const mapRef = useRef(null);
  const [plotsUnitsData, setPlotsUnitsData] = useState([]);
  const searchControlRef = useRef(null);
  const selectedPlotLayerRef = useRef(null);
  const [currentStyleMode, setCurrentStyleMode] = useState("Unit_Status");

  const imageOverlays = [
    {
      imageUrl: vipingo,
      imageBounds: [
        [24.78, -177.93],
        [24.47, -176.56],
      ],
    },
  ];

  const addImageOverlays = () => {
    imageOverlays.forEach(({ imageUrl, imageBounds }) => {
      if (mapRef.current) {
        const imageOverlay = L.imageOverlay(imageUrl, imageBounds).addTo(mapRef.current);
        imageOverlay.bringToFront();
      } else {
        console.error("Map instance not available.");
      }
    });
  };

  const goldenPinIcon = L.icon({
    iconUrl: pin,
    iconSize: [100, 80],
    iconAnchor: [16, 48],
  });

  useEffect(() => {
    let geoJsonLayer;

    if (!mapRef.current && geojsonData.features.length > 0) {
      const [centerLat, centerLng] = calculateCenter(geojsonData.features[0].geometry.coordinates[0]);

      mapRef.current = L.map("map", { minZoom: 6 }).setView([centerLat, centerLng], 9);
      setMaxBounds(mapRef.current);

      L.tileLayer("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzklEQVRIDb3BAQEAAAABIP5mz6iDBTAYzFRMXAuN7ABTGYMKEzzLMvBDFRgwoTPsDAUxiwwTM82zIAxUYMKRM+wMBTGLDNEzPMDEUYMKRM+wMBTGJ6m8F9B5K3RgEoYMKRM+wMBTGYsM0TMsxMVGAwTKhM+wMBTGJ6SBDHAk4MwBaxjAzRM9bAtwRWWoMKEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwF9AAAAABJRU5ErkJggg==").addTo(mapRef.current);
      addImageOverlays();
    }

    if (!geoJsonLayer) {
      geoJsonLayer = L.geoJSON(geojsonData, {
        style: styleFunction,
        onEachFeature: onEachFeature,
      }).addTo(mapRef.current);
    }

    if (!plotsUnitsData.length) {
      fetchPlotUnitsData();
    }

    if (mapRef.current && !searchControlRef.current) {
      initializeSearchControl(geoJsonLayer);
    }
  }, [currentStyleMode, geojsonData.features.length, plotsUnitsData.length]);

  const calculateCenter = (coordinates) => {
    const centerLng = coordinates.reduce((sum, coord) => sum + coord[0], 0) / coordinates.length;
    const centerLat = coordinates.reduce((sum, coord) => sum + coord[1], 0) / coordinates.length;
    return [centerLat, centerLng];
  };

  const setMaxBounds = (map) => {
    const topLeftCoordinates = [29.0, -178.08];
    const bottomRightCoordinates = [24.4, -174.35];
    map.setMaxBounds([topLeftCoordinates, bottomRightCoordinates]);
  };

  const fetchPlotUnitsData = () => {
    fetch("https://workspace.optiven.co.ke/api/plots")
      .then((response) => response.json())
      .then((data) => setPlotsUnitsData(data))
      .catch((error) => console.error("Error fetching plot units data:", error));
  };

  const initializeSearchControl = (geoJsonLayer) => {
    searchControlRef.current = new L.Control.Search({
      layer: geoJsonLayer,
      propertyName: "Unit_Number",
      marker: false,
      autoCollapse: true,
      position: "topright",
      initial: false,
      style: "background-color: #f0feff; border: 2px solid #0078d4; border-radius: 4px; padding: 5px;",
      textPlaceholder: "Enter Plot Number...",
      textErr: "Plot not found!",
      textCancel: "Cancel",
      searchMove: false,
    });

    customizeSearchControl();

    searchControlRef.current.addTo(mapRef.current);

    const searchInput = document.querySelector(".search-input");
    searchInput.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        searchControlRef.current._searchText(searchInput.value);
      }
    });
  };

  const customizeSearchControl = () => {
    searchControlRef.current._moveToLocation = function (latlng, title, map) {
      map.setView(latlng, map.getZoom(), { animate: true, duration: 0.5 });
    };

    searchControlRef.current._searchText = (text) => {
      if (!isNaN(text)) {
        text = "og" + text;
      }
      L.Control.Search.prototype._searchText.call(searchControlRef.current, text);
    };

    searchControlRef.current.on("search:locationfound", (e) => handleSearchResult(e));
  };

  const handleSearchResult = (e) => {
    if (selectedPlotLayerRef.current) {
      mapRef.current.removeLayer(selectedPlotLayerRef.current);
    }

    const selectedPlot = e.layer;
    selectedPlotLayerRef.current = L.marker(selectedPlot.getBounds().getCenter(), { icon: goldenPinIcon }).addTo(mapRef.current);

    mapRef.current.fitBounds(e.layer.getBounds());

    setTimeout(() => {
      if (selectedPlotLayerRef.current) {
        mapRef.current.removeLayer(selectedPlotLayerRef.current);
      }
    }, 3000);
  };

  const styleFunction = (feature) => {
    const Unit_Number = feature.properties.Unit_Number;
    let fillColor = "#f0fdff";

    if (plotsUnitsData.length > 0) {
      const plotsInfo = plotsUnitsData.find((plots) => plots.Unit_Number === Unit_Number);

      if (plotsInfo) {
        if (currentStyleMode === "Unit_Status") {
          fillColor = getStatusColor(plotsInfo.Unit_Status);
        } else if (currentStyleMode === "Unit_Type") {
          fillColor = getTypeColor(plotsInfo);
        }
      }
    }

    return { fillColor, fillOpacity: 0.9, color: "black", weight: 2 };
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Open":
        return "#49e183";
      case "Reserved":
        return "#ffd700";
      case "Sold":
        return "#db1515";
      default:
        return "#f0fdff";
    }
  };

  const getTypeColor = (plotsInfo) => {
    const landSizeRaw = plotsInfo.Unit_Type;
    const unitNumberRaw = plotsInfo.Unit_Number;
  
    if (!landSizeRaw || !unitNumberRaw) {
      return "#f0fdff"; // Default fill color if no land size or unit number is specified
    }
  
    // Remove the "VR" prefix and convert the remaining part to a number
    const unitNumber = parseInt(unitNumberRaw.replace("VR", ""), 10);
    
    // Remove " HA" from the land size and convert to acres
    const landSizeAcres = parseFloat(landSizeRaw.replace(" HA", "")) * 2.47105;
  
    const sizeColors = {
      Eighth: "#2098ae",
      Quarter: "#e86100",
      Half: "#ffe135",
      Acre: "#660000",
      "Prime Eighth": "#7f00ff",
      "Prime Quarter": "#e231aa",
    };
  
    const sizeRanges = {
      Eighth: { min: 0, max: 0.19 },
      Quarter: { min: 0.2, max: 0.3 },
      Half: { min: 0.4, max: 0.6 },
      Acre: { min: 0.8, max: 1.3 },
      "Prime Eighth": { min: 0, max: 0.19, isPrime: true },
      "Prime Quarter": { min: 0.2, max: 0.39, isPrime: true },
    };
  
    // Check if the unit number falls within the prime ranges first
    const isPrimeUnitNumber = 
      (unitNumber >= 890 && unitNumber <= 1006) || 
      (unitNumber >= 1396 && unitNumber <= 1588);
  
    if (isPrimeUnitNumber) {
      if (landSizeAcres <= 0.19) {
        return sizeColors["Prime Eighth"];
      } else if (landSizeAcres >= 0.2 && landSizeAcres <= 0.3) {
        return sizeColors["Prime Quarter"];
      }
    }
  
    // If not a prime area, assign colors based on size ranges
    const matchingSize = Object.entries(sizeRanges).find(([size, range]) => {
      return landSizeAcres >= range.min && landSizeAcres <= range.max && !range.isPrime;
    });
  
    if (matchingSize) {
      return sizeColors[matchingSize[0]];
    }
  
    return "#f0fdff"; // Default color if no match is found
  };  

  const onEachFeature = (feature, layer) => {
    let popupContent = "<b>Plot Information</b><br />";
    if (feature.properties.project) {
      popupContent += `Plots Name: ${feature.properties.project}<br />`;
    }

    const Unit_Number = feature.properties.Unit_Number;
    const plotsInfo = plotsUnitsData.find((plots) => plots.Unit_Number === Unit_Number);

    if (plotsInfo) {
      const formattedPrice = `Ksh. ${formatPriceWithCommas(plotsInfo.Cash_Price)}`;
      const plotIdLabel = L.divIcon({
        className: "plot-id-label",
        html: `<strong><span style="font-size: 8px; font-weight: bold;">${plotsInfo.Unit_Number}</span></strong>`,
        iconSize: [20, 10],
      });

      L.marker(layer.getBounds().getCenter(), { icon: plotIdLabel }).addTo(mapRef.current);

      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        layer.on("click", () => layer.openTooltip());
      } else {
        layer.on("mouseover", () => layer.openTooltip());
        layer.on("mouseout", () => layer.closeTooltip());
      }

      layer.bindTooltip(
        `<b>Plots Information</b><br />
        <b>Project Name:</b> ${plotsInfo.Name}<br />
        <b>Status:</b> ${plotsInfo.Unit_Status}<br />
        <b>Price:</b> ${formattedPrice}<br />
        <b>Plot Size:</b> ${plotsInfo.Unit_Type} HA<br />`,
        { className: "custom-tooltip" }
      );
    }
  };

  const formatPriceWithCommas = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div id="map" style={{ height: "450px", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "180px",
          transform: "translateX(-50%)",
          zIndex: "1000",
          backgroundColor: "white",
          padding: "5px",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        <span className="label-text">View Options Status / Size</span>
        <br />
        <label style={{ display: "inline-block", margin: "0 auto" }}>
          <input
            type="checkbox"
            className="toggle toggle-accent"
            checked={currentStyleMode === "Unit_Type"}
            onChange={(e) =>
              setCurrentStyleMode(e.target.checked ? "Unit_Type" : "Unit_Status")
            }
          />
          <span className="toggle-mark"></span>
        </label>
      </div>

      <style>
        {`
          .leaflet-tooltip {
            font-family: 'Lausanne-Regular', sans-serif;
            background-color: #86bc39;
            color: black;
          }
          .leaflet-tooltip.leaflet-tooltip-reserved {
            color: black;
          }
          .legend {
            position: absolute;
            bottom: 10px;
            left: 10px;
            background-color: white;
            padding: 5px;
            border: 1px solid #ccc;
            border-radius: 5px;
            z-index: 999;
          }
          .legend-item {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
          }
          .legend-color {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            border-radius: 50%;
          }
        `}
      </style>

      {currentStyleMode === "Unit_Type" && (
        <div className="legend">
          <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: "#2098ae" }}></div>
            Eighth
          </div>
          <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: "#e86100" }}></div>
            Quarter
          </div>
          <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: "#ffe135" }}></div>
            Half
          </div>
          <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: "#660000" }}></div>
            Acre
          </div>
          <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: "#7f00ff" }}></div>
            Prime Eighth
          </div>
          <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: "#e231aa" }}></div>
            Prime Quarter
          </div>
        </div>
      )}
    </div>
  );
};

export default MapComponent;
