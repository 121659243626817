// DashboardWorkDiary.jsx

import Sidebar from "./Sidebar";
import axios from "axios";
import { getISOWeek } from "date-fns";
import { motion } from "framer-motion";
import {
  Clock,
  Activity,
  Briefcase,
  User,
  CheckCircle,
  Calendar,
  Mail,
  Phone,
  AlertTriangle,
  Target,
} from "lucide-react";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  RadialBarChart,
  RadialBar,
} from "recharts";

const DashboardWorkDiary = () => {
  const [activities, setActivities] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [currentWeek, setCurrentWeek] = useState(0);
  const [focusAreaBreakdown, setFocusAreaBreakdown] = useState([]);
  const [complianceData, setComplianceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [totalHoursLogged, setTotalHoursLogged] = useState(0);
  const [numberOfActivities, setNumberOfActivities] = useState(0);
  const [topFocusArea, setTopFocusArea] = useState("N/A");
  const [focusAreaTimePerDay, setFocusAreaTimePerDay] = useState([]);
  const [focusAreasList, setFocusAreasList] = useState([]);

  const COLORS = ["#3B82F6", "#10B981", "#F59E0B", "#EF4444", "#8B5CF6"];

  useEffect(() => {
    const fetchCurrentWeek = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/work-diary/current-week`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setCurrentWeek(response.data.weekNumber);
        setSelectedWeek(response.data.weekNumber);
      } catch (err) {
        console.error("Error fetching current week:", err);
        setError("Failed to fetch current week. Please try again later.");
      }
    };
    fetchCurrentWeek();
  }, [token]);

  useEffect(() => {
    if (selectedWeek === null) return;

    const fetchData = async () => {
      try {
        const activitiesPromise = axios.get(
          `https://workspace.optiven.co.ke/api/work-diary/activities/user/${user.user_id}?week=${selectedWeek}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const profilePromise = axios.get(
          `https://workspace.optiven.co.ke/api/work-diary/user-profile/${user.user_id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const compliancePromise = axios.get(
          `https://workspace.optiven.co.ke/api/work-diary/compliance/${user.user_id}?week=${selectedWeek}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const focusAreaBreakdownPromise = axios.get(
          `https://workspace.optiven.co.ke/api/work-diary/focus-area-breakdown/${user.user_id}?week=${selectedWeek}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const totalHoursPromise = axios.get(
          `https://workspace.optiven.co.ke/api/work-diary/total-hours/${user.user_id}?week=${selectedWeek}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const [
          activitiesResponse,
          profileResponse,
          complianceResponse,
          focusAreaBreakdownResponse,
          totalHoursResponse,
        ] = await Promise.allSettled([
          activitiesPromise,
          profilePromise,
          compliancePromise,
          focusAreaBreakdownPromise,
          totalHoursPromise,
        ]);

        if (activitiesResponse.status === "fulfilled") {
          const activitiesData = Array.isArray(activitiesResponse.value.data)
            ? activitiesResponse.value.data
            : [];
          setActivities(activitiesData);

          // Calculate Number of Activities and Top Focus Area
          let focusAreaHours = {};
          activitiesData.forEach((activity) => {
            if (activity.focus_area && activity.focus_area !== "Break") {
              const start = new Date(`1970-01-01T${activity.start_time}`);
              const end = new Date(`1970-01-01T${activity.end_time}`);
              const hours = (end - start) / (1000 * 60 * 60);

              focusAreaHours[activity.focus_area] =
                (focusAreaHours[activity.focus_area] || 0) + hours;
            }
          });

          setNumberOfActivities(activitiesData.length);

          // Determine Top Focus Area (excluding 'Break')
          let maxHours = 0;
          let topArea = "N/A";
          for (const [area, hours] of Object.entries(focusAreaHours)) {
            if (hours > maxHours) {
              maxHours = hours;
              topArea = area;
            }
          }
          setTopFocusArea(topArea);

          // Prepare data for Focus Area Time Per Day chart
          computeFocusAreaTimePerDay(
            activitiesData.filter(
              (activity) => activity.focus_area !== "Break"
            ),
            selectedWeek
          );
        } else {
          console.error(
            "Error fetching activities:",
            activitiesResponse.reason
          );
          setError("Failed to fetch activities.");
        }

        if (profileResponse.status === "fulfilled") {
          const userProfileData = profileResponse.value.data || {};
          setUserProfile(userProfileData);
        } else {
          console.error("Error fetching user profile:", profileResponse.reason);
        }

        if (complianceResponse.status === "fulfilled") {
          const complianceDataResult =
            complianceResponse.value.data &&
            complianceResponse.value.data.complianceRate !== undefined
              ? complianceResponse.value.data
              : null;
          setComplianceData(complianceDataResult);
        } else {
          console.error(
            "Error fetching compliance data:",
            complianceResponse.reason
          );
        }

        if (focusAreaBreakdownResponse.status === "fulfilled") {
          const focusAreaBreakdownData = Array.isArray(
            focusAreaBreakdownResponse.value.data
          )
            ? focusAreaBreakdownResponse.value.data
            : [];
          setFocusAreaBreakdown(
            focusAreaBreakdownData
              .filter((item) => item.name !== "Break")
              .map((item) => ({
                name: item.name,
                value: parseFloat(item.hours),
              }))
          );
        } else {
          console.error(
            "Error fetching focus area breakdown:",
            focusAreaBreakdownResponse.reason
          );
        }

        if (totalHoursResponse.status === "fulfilled") {
          const totalHoursLoggedData =
            totalHoursResponse.value.data.totalHoursLogged || 0;
          setTotalHoursLogged(totalHoursLoggedData);
        } else {
          console.error(
            "Error fetching total hours:",
            totalHoursResponse.reason
          );
        }

        setLoading(false);
      } catch (err) {
        console.error("Unexpected error:", err);
        setError("An unexpected error occurred. Please try again later.");
        setLoading(false);
      }
    };
    fetchData();
  }, [token, user.user_id, selectedWeek]);

  // Function to format hours into hours and minutes
  const formatHours = (hours) => {
    const totalMinutes = Math.round(hours * 60);
    const hrs = Math.floor(totalMinutes / 60);
    const mins = totalMinutes % 60;
    return `${hrs} hrs ${mins} min`;
  };

  const computeFocusAreaTimePerDay = (activities, selectedWeek) => {
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const focusAreasSet = new Set();
    activities.forEach((activity) => {
      if (activity.focus_area && activity.focus_area !== "Break") {
        focusAreasSet.add(activity.focus_area);
      }
    });
    const focusAreas = Array.from(focusAreasSet);
    setFocusAreasList(focusAreas);

    const data = daysOfWeek.map((day) => ({ day }));

    const filteredActivities = activities.filter((activity) => {
      const activityDate = new Date(activity.date);
      const activityWeek = getWeekNumber(activityDate);
      return activityWeek === selectedWeek;
    });

    filteredActivities.forEach((activity) => {
      const activityDate = new Date(activity.date);
      const dayName = activityDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      const index = daysOfWeek.indexOf(dayName);
      if (index !== -1) {
        const start = new Date(`1970-01-01T${activity.start_time}`);
        const end = new Date(`1970-01-01T${activity.end_time}`);
        const hours = (end - start) / (1000 * 60 * 60);
        const focusArea = activity.focus_area;
        if (focusArea !== "Break") {
          if (!data[index][focusArea]) {
            data[index][focusArea] = 0;
          }
          data[index][focusArea] += hours;
        }
      }
    });

    setFocusAreaTimePerDay(data);
  };

  const getWeekNumber = (date) => {
    return getISOWeek(date);
  };

  const getComplianceColor = (percentage) => {
    if (percentage >= 90) return "#10B981"; // Green
    if (percentage >= 70) return "#F59E0B"; // Yellow
    return "#EF4444"; // Red
  };

  const LoadingSpinner = () => (
    <div className="flex justify-center items-center h-screen bg-gradient-to-br from-blue-100 to-indigo-200">
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
        className="h-16 w-16 border-t-4 border-b-4 border-blue-500 rounded-full"
      />
    </div>
  );

  if (loading) return <LoadingSpinner />;

  return (
    <Sidebar>
      <div className="p-6 bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen">
        <h1 className="text-4xl font-bold mb-8 text-gray-800">
          My Work Diary Dashboard
        </h1>

        {/* Week Selection Dropdown */}
        <div className="mb-8">
          <label htmlFor="weekSelect" className="mr-2">
            Select Week:
          </label>
          <select
            id="weekSelect"
            value={selectedWeek}
            onChange={(e) => setSelectedWeek(parseInt(e.target.value))}
            className="p-2 border border-gray-300 rounded"
          >
            <option value={currentWeek}>Current Week</option>
            <option value={currentWeek - 1}>Previous Week</option>
            <option value={currentWeek - 2}>2 Weeks Ago</option>
            <option value={currentWeek - 3}>3 Weeks Ago</option>
          </select>
        </div>

        {/* Display error message if any */}
        {error && (
          <div className="alert alert-error shadow-lg mb-6">
            <div>
              <AlertTriangle className="stroke-current flex-shrink-0 h-6 w-6" />
              <span>{error}</span>
            </div>
          </div>
        )}

        {/* User Profile Section */}
        <div className="bg-white rounded-lg shadow-xl p-6 mb-8">
          <div className="flex flex-col md:flex-row justify-between items-center mb-6">
            <div className="flex items-center mb-4 md:mb-0">
              <div className="bg-blue-500 text-white rounded-full w-20 h-20 flex items-center justify-center text-3xl font-bold mr-4">
                {userProfile.name?.charAt(0) || "U"}
              </div>
              <div>
                <h2 className="text-2xl font-semibold text-gray-800">
                  {userProfile.name || "User"}
                </h2>
                <p className="text-gray-600">{userProfile.role || "Role"}</p>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <div className="flex items-center text-gray-600 mb-2">
                <Mail className="h-5 w-5 mr-2" />
                <span>{userProfile.email || "Email not provided"}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <Phone className="h-5 w-5 mr-2" />
                <span>{userProfile.phone || "Phone not provided"}</span>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <ProfileItem
              icon={<Briefcase className="h-5 w-5 text-blue-500" />}
              label="Department"
              value={userProfile.department || "N/A"}
            />
            <ProfileItem
              icon={<User className="h-5 w-5 text-green-500" />}
              label="Supervisor"
              value={userProfile.supervisor || "N/A"}
            />
            <ProfileItem
              icon={<Calendar className="h-5 w-5 text-purple-500" />}
              label="Current Week"
              value={currentWeek}
            />
          </div>
        </div>

        {/* Summary Metrics */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          <MetricCard
            title="Total Hours Logged"
            value={formatHours(totalHoursLogged)}
            icon={<Clock className="h-10 w-10 text-blue-500" />}
            color="blue"
          />
          <MetricCard
            title="Number of Activities"
            value={numberOfActivities}
            icon={<Activity className="h-10 w-10 text-green-500" />}
            color="green"
          />
          <MetricCard
            title="Top Focus Area"
            value={topFocusArea}
            icon={<Target className="h-10 w-10 text-purple-500" />}
            color="purple"
          />
        </div>

        {/* Compliance Section */}
        <div className="bg-white rounded-lg shadow-xl p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            Compliance Status
          </h2>
          {complianceData ? (
            <div className="flex flex-wrap items-start">
              <div className="w-full md:w-1/3 mb-4 md:mb-0">
                <ResponsiveContainer width="100%" height={200}>
                  <RadialBarChart
                    cx="50%"
                    cy="50%"
                    innerRadius="60%"
                    outerRadius="80%"
                    barSize={10}
                    data={[
                      {
                        name: "Compliance",
                        value: complianceData.complianceRate,
                        fill: getComplianceColor(complianceData.complianceRate),
                      },
                    ]}
                  >
                    <RadialBar
                      minAngle={15}
                      background
                      clockWise
                      dataKey="value"
                    />
                    <text
                      x="50%"
                      y="50%"
                      textAnchor="middle"
                      dominantBaseline="middle"
                      className="text-2xl font-bold"
                      fill={getComplianceColor(complianceData.complianceRate)}
                    >
                      {`${complianceData.complianceRate}%`}
                    </text>
                  </RadialBarChart>
                </ResponsiveContainer>
              </div>
              <div className="w-full md:w-2/3 md:pl-6">
                <div className="flex items-center mb-2">
                  {complianceData.isCompliant ? (
                    <CheckCircle className="h-6 w-6 text-green-500 mr-2" />
                  ) : (
                    <AlertTriangle className="h-6 w-6 text-yellow-500 mr-2" />
                  )}
                  <span className="text-lg font-medium">
                    {complianceData.isCompliant
                      ? "Fully Compliant"
                      : "Improvement Needed"}
                  </span>
                </div>
                {!complianceData.isCompliant && (
                  <p className="text-sm text-yellow-700 mb-2">
                    Please ensure you log the required hours each day to
                    maintain compliance.
                  </p>
                )}
                <div className="bg-gray-100 rounded-lg p-3 mt-2">
                  <h3 className="font-semibold mb-2">Daily Breakdown</h3>
                  <div className="grid grid-cols-3 gap-2">
                    {complianceData.complianceData.map((day, index) => (
                      <div key={index} className="text-xs">
                        <span className="font-medium">{day.day}: </span>
                        {day.isCompliant ? (
                          <span className="text-green-500">Compliant</span>
                        ) : (
                          <span className="text-red-500">
                            {formatHours(day.hoursWorked)}/
                            {formatHours(day.requiredHours)}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-gray-600">
              No compliance data available for the selected week.
            </div>
          )}
        </div>

        {/* Charts Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
          {/* Focus Area Breakdown */}
          <ChartCard title="Focus Area Breakdown">
            {focusAreaBreakdown.length > 0 ? (
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={focusAreaBreakdown}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) =>
                      `${name} ${(percent * 100).toFixed(0)}%`
                    }
                  >
                    {focusAreaBreakdown.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip formatter={(value) => formatHours(value)} />
                </PieChart>
              </ResponsiveContainer>
            ) : (
              <div className="text-center text-gray-500">
                No data available for the selected week.
              </div>
            )}
          </ChartCard>

          {/* Focus Area Time Per Day */}
          <ChartCard title="Focus Areas Time Spent Per Day">
            {focusAreaTimePerDay.length > 0 && focusAreasList.length > 0 ? (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={focusAreaTimePerDay}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis allowDecimals={false} />
                  <Tooltip formatter={(value) => formatHours(value)} />
                  {focusAreasList.map((focusArea, index) => (
                    <Bar
                      key={focusArea}
                      dataKey={focusArea}
                      stackId="a"
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div className="text-center text-gray-500">
                No data available for the selected week.
              </div>
            )}
          </ChartCard>
        </div>

        {/* Activity History */}
        <div className="bg-white rounded-lg shadow-xl overflow-hidden">
          <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-800">
              Recent Activity History
            </h2>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Focus Area
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Time
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {activities.length > 0 ? (
                  activities.slice(0, 5).map((activity, index) => (
                    <tr
                      key={index}
                      className="hover:bg-gray-50 transition-colors duration-200"
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(activity.date).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {activity.focus_area}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {activity.activity_description}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {activity.start_time} - {activity.end_time}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center py-4 text-gray-500">
                      No activity history available for the selected week.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

const ProfileItem = ({ icon, label, value }) => (
  <div className="flex items-center">
    {icon}
    <div className="ml-2">
      <p className="text-sm font-medium text-gray-500">{label}</p>
      <p className="text-lg font-semibold text-gray-800">{value}</p>
    </div>
  </div>
);

const MetricCard = ({ title, value, icon, color }) => {
  const colorClasses = {
    blue: "border-blue-500",
    green: "border-green-500",
    yellow: "border-yellow-500",
    purple: "border-purple-500",
  };

  return (
    <div
      className={`bg-white rounded-lg shadow-lg p-6 flex items-center border-l-4 ${colorClasses[color]} transform hover:scale-105 transition-transform duration-200`}
    >
      <div className="mr-4">{icon}</div>
      <div>
        <h2 className="text-sm font-medium text-gray-500">{title}</h2>
        <p className="text-2xl font-semibold text-gray-800">{value}</p>
      </div>
    </div>
  );
};

const ChartCard = ({ title, children }) => (
  <div className="card bg-base-100 shadow-xl mt-8 hover:shadow-2xl transition-shadow duration-300">
    <div className="card-body">
      <h2 className="card-title text-xl mb-4">{title}</h2>
      {children}
    </div>
  </div>
);

export default DashboardWorkDiary;
