import Sidebar from "./Sidebar";
import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import "jspdf-autotable";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
} from "recharts";

const HRAdminDashboard = () => {
  const token = useSelector((state) => state.user.token);

  // State variables for filters
  const [departments, setDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [focusAreas, setFocusAreas] = useState([]);
  const [selectedFocusAreas, setSelectedFocusAreas] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [aggregationLevel, setAggregationLevel] = useState("Focus Area");

  // State variables for data and loading
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const [loading, setLoading] = useState({
    departments: false,
    employees: false,
    focusAreas: false,
    data: false,
    complianceData: false,
  });
  const [error, setError] = useState({
    departments: null,
    employees: null,
    focusAreas: null,
    data: null,
    complianceData: null,
  });

  // State variables for pagination and search
  const [currentPage, setCurrentPage] = useState(1);
  const [complianceCurrentPage, setComplianceCurrentPage] = useState(1);
  const [complianceSearchTerm, setComplianceSearchTerm] = useState("");
  const rowsPerPage = 10;

  // Fetch departments on component mount
  useEffect(() => {
    const fetchDepartments = async () => {
      setLoading((prev) => ({ ...prev, departments: true }));
      setError((prev) => ({ ...prev, departments: null }));
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/work-diary/hr-admin/departments",
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setDepartments(
          response.data.map((d) => ({ value: d.dp_name, label: d.dp_name }))
        );
      } catch (err) {
        console.error("Error fetching departments:", err);
        setError((prev) => ({
          ...prev,
          departments: "Failed to fetch departments.",
        }));
      } finally {
        setLoading((prev) => ({ ...prev, departments: false }));
      }
    };
    fetchDepartments();
  }, [token]);

  // Fetch employees based on department selection
  useEffect(() => {
    const fetchEmployees = async () => {
      if (selectedDepartments.length === 0) {
        setEmployees([]);
        setSelectedEmployees([]);
        return;
      }
      setLoading((prev) => ({ ...prev, employees: true }));
      setError((prev) => ({ ...prev, employees: null }));
      try {
        const params = {
          departments: selectedDepartments.map((d) => d.value).join(","),
        };
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/work-diary/hr-admin/employees",
          { headers: { Authorization: `Bearer ${token}` }, params }
        );
        setEmployees(
          response.data.map((e) => ({ value: e.user_id, label: e.name }))
        );
      } catch (err) {
        console.error("Error fetching employees:", err);
        setError((prev) => ({
          ...prev,
          employees: "Failed to fetch employees.",
        }));
      } finally {
        setLoading((prev) => ({ ...prev, employees: false }));
      }
    };
    fetchEmployees();
  }, [selectedDepartments, token]);

  // Fetch focus areas based on department and employee selection
  useEffect(() => {
    const fetchFocusAreas = async () => {
      if (selectedDepartments.length === 0 && selectedEmployees.length === 0) {
        setFocusAreas([]);
        setSelectedFocusAreas([]);
        return;
      }
      setLoading((prev) => ({ ...prev, focusAreas: true }));
      setError((prev) => ({ ...prev, focusAreas: null }));
      try {
        const params = {};
        if (selectedDepartments.length > 0) {
          params.departments = selectedDepartments
            .map((d) => d.value)
            .join(",");
        }
        if (selectedEmployees.length > 0) {
          params.employees = selectedEmployees.map((e) => e.value).join(",");
        }
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/work-diary/hr-admin/focus-areas",
          { headers: { Authorization: `Bearer ${token}` }, params }
        );
        setFocusAreas(response.data.map((fa) => ({ value: fa, label: fa })));
      } catch (err) {
        console.error("Error fetching focus areas:", err);
        setError((prev) => ({
          ...prev,
          focusAreas: "Failed to fetch focus areas.",
        }));
      } finally {
        setLoading((prev) => ({ ...prev, focusAreas: false }));
      }
    };
    fetchFocusAreas();
  }, [selectedDepartments, selectedEmployees, token]);

  // Fetch data based on filters
  const fetchData = useCallback(async () => {
    if (!startDate || !endDate) {
      setError((prev) => ({
        ...prev,
        data: "Please select both start date and end date.",
      }));
      return;
    }
    setLoading((prev) => ({ ...prev, data: true, complianceData: true }));
    setError((prev) => ({ ...prev, data: null, complianceData: null }));
    setData([]);
    setChartData([]);
    setComplianceData([]);
    setCurrentPage(1);
    setComplianceCurrentPage(1);
    try {
      const params = {
        startDate: startDate.toISOString().split("T")[0],
        endDate: endDate.toISOString().split("T")[0],
        departments: selectedDepartments.map((d) => d.value).join(","),
        employees: selectedEmployees.map((e) => e.value).join(","),
        focusAreas: selectedFocusAreas.map((fa) => fa.value).join(","),
      };
      const [dataResponse, complianceResponse] = await Promise.all([
        axios.get(
          "https://workspace.optiven.co.ke/api/work-diary/hr-admin/focus-areas/report",
          {
            headers: { Authorization: `Bearer ${token}` },
            params,
          }
        ),
        axios.get(
          "https://workspace.optiven.co.ke/api/work-diary/hr-admin/compliance",
          {
            headers: { Authorization: `Bearer ${token}` },
            params,
          }
        ),
      ]);
      // Filter out 'Break' focus area and sort data by hours_logged in descending order
      const sortedData = dataResponse.data
        .filter((item) => item.focus_area !== "Break")
        .sort((a, b) => b.hours_logged - a.hours_logged); // <-- Added sorting here

      setData(sortedData);
      prepareChartData(sortedData);
      setComplianceData(
        complianceResponse.data
          .map((item) => ({
            ...item,
            compliance_percentage: parseFloat(item.compliance_percentage) || 0,
          }))
          .sort((a, b) => a.compliance_percentage - b.compliance_percentage)
      );
    } catch (err) {
      console.error("Error fetching data:", err);
      setError((prev) => ({
        ...prev,
        data: "Failed to fetch data.",
        complianceData: "Failed to fetch compliance data.",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, data: false, complianceData: false }));
    }
  }, [
    startDate,
    endDate,
    selectedDepartments,
    selectedEmployees,
    selectedFocusAreas,
    token,
  ]);

  // Prepare chart data based on aggregation level
  const prepareChartData = useCallback(
    (data) => {
      const fieldMap = {
        "Focus Area": "focus_area",
        Department: "department",
        Employee: "employee_name",
      };
      const keyField = fieldMap[aggregationLevel];

      const aggregatedData = data.reduce((acc, curr) => {
        const key = curr[keyField];
        if (!acc[key]) {
          acc[key] = { name: key, hours: 0 };
        }
        acc[key].hours += parseFloat(curr.hours_logged);
        return acc;
      }, {});
      setChartData(
        Object.values(aggregatedData).sort((a, b) => b.hours - a.hours)
      );
    },
    [aggregationLevel]
  );

  useEffect(() => {
    if (data.length > 0) {
      prepareChartData(data);
    }
  }, [data, aggregationLevel, prepareChartData]);

  // Memoized filtered and paginated data
  const filteredComplianceData = useMemo(() => {
    return complianceData.filter(
      (item) =>
        item.employee_name
          .toLowerCase()
          .includes(complianceSearchTerm.toLowerCase()) ||
        item.department
          .toLowerCase()
          .includes(complianceSearchTerm.toLowerCase())
    );
  }, [complianceData, complianceSearchTerm]);

  const paginatedComplianceData = useMemo(() => {
    const startIndex = (complianceCurrentPage - 1) * rowsPerPage;
    return filteredComplianceData.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredComplianceData, complianceCurrentPage]);

  const complianceTotalPages = Math.ceil(
    filteredComplianceData.length / rowsPerPage
  );

  // Helper functions
  const formatHours = (hours) => {
    const h = Math.floor(hours);
    const m = Math.round((hours - h) * 60);
    return `${h} hrs ${m} min`;
  };

  const getColorForFocusArea = (name) => {
    const colorPalette = [
      "#4F46E5",
      "#22C55E",
      "#F97316",
      "#EF4444",
      "#3B82F6",
      "#F59E0B",
      "#10B981",
      "#8B5CF6",
      "#EC4899",
      "#6366F1",
      "#14B8A6",
      "#D946EF",
      "#F43F5E",
      "#0EA5E9",
      "#84CC16",
      "#A855F7",
      "#FB923C",
      "#64748B",
      "#06B6D4",
      "#DC2626",
    ];
    const hash = name
      .split("")
      .reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
    return colorPalette[Math.abs(hash) % colorPalette.length];
  };

  // Export functions
  const exportToExcel = (data, filename) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  const exportToPDF = (data, filename, columns) => {
    const doc = new jsPDF();
    doc.text(filename, 14, 16);
    doc.autoTable({
      startY: 20,
      head: [columns],
      body: data.map((item) => columns.map((col) => item[col])),
    });
    doc.save(`${filename}.pdf`);
  };

  return (
    <Sidebar>
      <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-100 min-h-screen">
        <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">
          HR Dashboard
        </h1>

        {/* Filters Section */}
        <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Department Selector */}
            <div>
              <label className="block text-gray-700 font-semibold mb-2">
                Departments
              </label>
              <Select
                options={departments}
                isMulti
                onChange={setSelectedDepartments}
                placeholder="Select Departments"
                isLoading={loading.departments}
                isDisabled={loading.departments}
              />
              {error.departments && (
                <div className="text-red-500 mt-2">{error.departments}</div>
              )}
            </div>

            {/* Employee Selector */}
            <div>
              <label className="block text-gray-700 font-semibold mb-2">
                Employees
              </label>
              <Select
                options={employees}
                isMulti
                onChange={setSelectedEmployees}
                placeholder="Select Employees"
                isLoading={loading.employees}
                isDisabled={loading.employees || employees.length === 0}
              />
              {error.employees && (
                <div className="text-red-500 mt-2">{error.employees}</div>
              )}
            </div>

            {/* Focus Area Selector */}
            <div>
              <label className="block text-gray-700 font-semibold mb-2">
                Focus Areas
              </label>
              <Select
                options={focusAreas}
                isMulti
                onChange={setSelectedFocusAreas}
                placeholder="Select Focus Areas"
                isLoading={loading.focusAreas}
                isDisabled={loading.focusAreas || focusAreas.length === 0}
              />
              {error.focusAreas && (
                <div className="text-red-500 mt-2">{error.focusAreas}</div>
              )}
            </div>

            {/* Date Range Pickers */}
            <div>
              <label className="block text-gray-700 font-semibold mb-2">
                Start Date
              </label>
              <DatePicker
                selected={startDate}
                onChange={setStartDate}
                placeholderText="Select Start Date"
                dateFormat="yyyy-MM-dd"
                className="input input-bordered w-full"
                maxDate={endDate || new Date()}
              />
            </div>
            <div>
              <label className="block text-gray-700 font-semibold mb-2">
                End Date
              </label>
              <DatePicker
                selected={endDate}
                onChange={setEndDate}
                placeholderText="Select End Date"
                dateFormat="yyyy-MM-dd"
                className="input input-bordered w-full"
                minDate={startDate}
                maxDate={new Date()}
              />
            </div>

            {/* Aggregation Level Selector */}
            <div>
              <label className="block text-gray-700 font-semibold mb-2">
                Aggregation Level
              </label>
              <Select
                options={[
                  { value: "Focus Area", label: "Focus Area" },
                  { value: "Department", label: "Department" },
                  { value: "Employee", label: "Employee" },
                ]}
                value={{ value: aggregationLevel, label: aggregationLevel }}
                onChange={(option) => setAggregationLevel(option.value)}
              />
            </div>

            {/* Fetch Data Button */}
            <div className="flex items-end">
              <button
                onClick={fetchData}
                className="btn btn-primary w-full"
                disabled={loading.data || loading.complianceData}
              >
                {loading.data || loading.complianceData ? (
                  <>
                    <span className="loading loading-spinner"></span>
                    Fetching Data...
                  </>
                ) : (
                  "Fetch Data"
                )}
              </button>
            </div>
          </div>
          {error.data && <div className="text-red-500 mt-4">{error.data}</div>}
        </div>

        {/* Charts */}
        <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
          {/* Added export buttons to Charts section */}
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-800">
              Hours Logged by {aggregationLevel}
            </h2>
            <div className="flex space-x-2">
              <button
                onClick={() => exportToExcel(chartData, "ChartData")}
                className="btn btn-success"
                disabled={chartData.length === 0}
              >
                Export to Excel
              </button>
              <button
                onClick={() =>
                  exportToPDF(chartData, "ChartData", ["name", "hours"])
                }
                className="btn btn-error"
                disabled={chartData.length === 0}
              >
                Export to PDF
              </button>
            </div>
          </div>
          {loading.data ? (
            <div className="text-center text-gray-500 py-8">
              <span className="loading loading-spinner loading-lg"></span>
              <div className="mt-4">Loading data...</div>
            </div>
          ) : chartData.length > 0 ? (
            <div className="bg-gray-50 p-4 rounded-lg shadow">
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip formatter={(value) => formatHours(value)} />
                  <Legend />
                  <Bar dataKey="hours" name="Hours Logged">
                    {chartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={getColorForFocusArea(entry.name)}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div className="text-center text-gray-500 py-8">
              No data available for the selected filters.
            </div>
          )}
        </div>

        {/* Compliance Data Table */}
        <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-800">
              Compliance Report
            </h2>
            <div className="flex space-x-2">
              <input
                type="text"
                placeholder="Search..."
                className="input input-bordered"
                value={complianceSearchTerm}
                onChange={(e) => setComplianceSearchTerm(e.target.value)}
              />
              <button
                onClick={() =>
                  exportToExcel(filteredComplianceData, "ComplianceReport")
                }
                className="btn btn-success"
                disabled={filteredComplianceData.length === 0}
              >
                Export to Excel
              </button>
              <button
                onClick={() =>
                  exportToPDF(filteredComplianceData, "ComplianceReport", [
                    "department",
                    "employee_name",
                    "hours_logged",
                    "required_hours",
                    "compliance_percentage",
                  ])
                }
                className="btn btn-error"
                disabled={filteredComplianceData.length === 0}
              >
                Export to PDF
              </button>
            </div>
          </div>
          {loading.complianceData ? (
            <div className="text-center text-gray-500 py-8">
              <span className="loading loading-spinner loading-lg"></span>
              <div className="mt-4">Loading compliance data...</div>
            </div>
          ) : filteredComplianceData.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Department</th>
                      <th>Employee</th>
                      <th>Hours Logged</th>
                      <th>Required Hours</th>
                      <th>Compliance %</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedComplianceData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.department}</td>
                        <td>{item.employee_name}</td>
                        <td>{formatHours(item.hours_logged)}</td>
                        <td>{formatHours(item.required_hours)}</td>
                        <td>{item.compliance_percentage}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* Pagination for Compliance Data */}
              <div className="flex justify-between items-center mt-4">
                <button
                  onClick={() =>
                    setComplianceCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={complianceCurrentPage === 1}
                  className="btn btn-outline"
                >
                  Previous
                </button>
                <span>
                  Page {complianceCurrentPage} of {complianceTotalPages}
                </span>
                <button
                  onClick={() =>
                    setComplianceCurrentPage((prev) =>
                      Math.min(prev + 1, complianceTotalPages)
                    )
                  }
                  disabled={complianceCurrentPage === complianceTotalPages}
                  className="btn btn-outline"
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <div className="text-center text-gray-500 py-8">
              No compliance data available for the selected filters.
            </div>
          )}
        </div>

        {/* Data Table */}
        <div className="bg-white shadow-lg rounded-lg p-6">
          {/* Added export buttons to Data Table */}
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-800">Data Table</h2>
            <div className="flex space-x-2">
              <button
                onClick={() => exportToExcel(data, "DataTable")}
                className="btn btn-success"
                disabled={data.length === 0}
              >
                Export to Excel
              </button>
              <button
                onClick={() =>
                  exportToPDF(data, "DataTable", [
                    "employee_name",
                    "department",
                    "focus_area",
                    "hours_logged",
                  ])
                }
                className="btn btn-error"
                disabled={data.length === 0}
              >
                Export to PDF
              </button>
            </div>
          </div>
          {loading.data ? (
            <div className="text-center text-gray-500 py-8">
              <span className="loading loading-spinner loading-lg"></span>
              <div className="mt-4">Loading data...</div>
            </div>
          ) : data.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Employee</th>
                      <th>Department</th>
                      <th>Focus Area</th>
                      <th>Hours Logged</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      .slice(
                        (currentPage - 1) * rowsPerPage,
                        currentPage * rowsPerPage
                      )
                      .map((item, index) => (
                        <tr key={index}>
                          <td>{item.employee_name}</td>
                          <td>{item.department}</td>
                          <td>{item.focus_area}</td>
                          <td>{formatHours(item.hours_logged)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {/* Pagination Controls */}
              <div className="flex justify-between items-center mt-4">
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={currentPage === 1}
                  className="btn btn-outline"
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {Math.ceil(data.length / rowsPerPage)}
                </span>
                <button
                  onClick={() =>
                    setCurrentPage((prev) =>
                      Math.min(prev + 1, Math.ceil(data.length / rowsPerPage))
                    )
                  }
                  disabled={
                    currentPage === Math.ceil(data.length / rowsPerPage)
                  }
                  className="btn btn-outline"
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <div className="text-center text-gray-500 py-8">
              No data available for the selected filters.
            </div>
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default HRAdminDashboard;
