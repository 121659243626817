import Sidebar from "./Sidebar";
import axios from "axios";
import jsPDF from "jspdf";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Cell,
} from "recharts";
import * as XLSX from "xlsx";
import "react-datepicker/dist/react-datepicker.css";
import "jspdf-autotable";
import "react-toastify/dist/ReactToastify.css";

// Ensure react-toastify styles are imported

const COLORS = [
  "#4F46E5",
  "#22C55E",
  "#F97316",
  "#EF4444",
  "#3B82F6",
  "#F59E0B",
  "#10B981",
  "#8B5CF6",
  "#EC4899",
  "#6366F1",
  "#14B8A6",
  "#D946EF",
  "#F43F5E",
  "#0EA5E9",
  "#84CC16",
  "#A855F7",
  "#FB923C",
  "#64748B",
  "#06B6D4",
  "#DC2626",
];

const API_BASE_URL = "https://workspace.optiven.co.ke/api/work-diary";

const SupervisorDashboard = () => {
  const { token, user } = useSelector((state) => state.user);
  const [state, setState] = useState({
    teamMembersList: [],
    selectedTeamMembers: [],
    focusAreas: [],
    selectedFocusAreas: [],
    startDate: null,
    endDate: null,
    reportData: [],
    chartData: [],
    complianceData: [],
    loading: false,
    sendingReminders: false, // Added this line
  });

  const updateState = (newState) =>
    setState((prevState) => ({ ...prevState, ...newState }));

  const fetchData = useCallback(
    async (url, params = {}) => {
      try {
        const response = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` },
          params,
        });
        return response.data;
      } catch (err) {
        console.error(`Error fetching data from ${url}:`, err);
        toast.error(`Failed to fetch data from ${url}.`);
        throw err;
      }
    },
    [token]
  );

  useEffect(() => {
    const fetchInitialData = async () => {
      updateState({ loading: true });
      try {
        const [teamMembers, focusAreas] = await Promise.all([
          fetchData(`${API_BASE_URL}/team-members/${user.user_id}`),
          fetchData(
            `${API_BASE_URL}/focus-areas-by-supervisor/${user.user_id}`
          ),
        ]);
        updateState({
          teamMembersList: teamMembers.map((member) => ({
            value: member.user_id,
            label: member.name,
            email: member.email,
          })),
          focusAreas: focusAreas.map((fa) => ({
            value: fa.focus_area,
            label: fa.focus_area,
          })),
        });
      } catch (err) {
        // Error already logged and toasted in fetchData
      } finally {
        updateState({ loading: false });
      }
    };
    fetchInitialData();
  }, [fetchData, user.user_id]);

  const fetchComplianceData = useCallback(async () => {
    updateState({ loading: true });
    try {
      const params = {
        employees: state.selectedTeamMembers.map((e) => e.value).join(","),
        startDate: state.startDate?.toISOString().split("T")[0],
        endDate: state.endDate?.toISOString().split("T")[0],
      };
      const data = await fetchData(`${API_BASE_URL}/compliance-report`, params);
      const sortedData = data
        .map((item) => ({
          ...item,
          compliance_percentage: parseFloat(item.compliance_percentage) || 0,
        }))
        .sort((a, b) => a.compliance_percentage - b.compliance_percentage);
      updateState({ complianceData: sortedData });
    } catch (err) {
      // Error already logged and toasted in fetchData
    } finally {
      updateState({ loading: false });
    }
  }, [fetchData, state.selectedTeamMembers, state.startDate, state.endDate]);

  const fetchReportData = useCallback(async () => {
    if (!state.startDate || !state.endDate) {
      toast.error("Please select both start date and end date.");
      return;
    }
    updateState({ loading: true });
    try {
      const params = {
        employees: state.selectedTeamMembers.map((e) => e.value).join(","),
        focusAreas: state.selectedFocusAreas.map((fa) => fa.value).join(","),
        startDate: state.startDate.toISOString().split("T")[0],
        endDate: state.endDate.toISOString().split("T")[0],
      };
      const data = await fetchData(
        `${API_BASE_URL}/supervisor/${user.user_id}/focus-areas/report`,
        params
      );
      const filteredData = data.filter((item) => item.focus_area !== "Break");
      updateState({ reportData: filteredData });
      prepareChartData(filteredData);
    } catch (err) {
      // Error already logged and toasted in fetchData
    } finally {
      updateState({ loading: false });
    }
  }, [
    fetchData,
    state.selectedTeamMembers,
    state.selectedFocusAreas,
    state.startDate,
    state.endDate,
    user.user_id,
  ]);

  const prepareChartData = useCallback((data) => {
    const aggregatedData = data.reduce((acc, curr) => {
      const key = curr.focus_area;
      if (!acc[key]) {
        acc[key] = { focus_area: curr.focus_area, hours: 0 };
      }
      acc[key].hours += parseFloat(curr.hours_logged);
      return acc;
    }, {});

    const totalHours = Object.values(aggregatedData).reduce(
      (sum, curr) => sum + curr.hours,
      0
    );
    const chartDataArray = Object.values(aggregatedData);
    const combinedChartData = combineSmallSegments(
      chartDataArray,
      0.02,
      totalHours
    );
    updateState({ chartData: combinedChartData });
  }, []);

  const combineSmallSegments = useCallback((data, threshold, totalHours) => {
    const { data: mainData, other } = data.reduce(
      (acc, curr) => {
        if (curr.hours / totalHours < threshold) {
          acc.other += curr.hours;
        } else {
          acc.data.push(curr);
        }
        return acc;
      },
      { data: [], other: 0 }
    );

    if (other > 0) {
      mainData.push({ focus_area: "Other", hours: other });
    }

    return mainData;
  }, []);

  const sendReminders = useCallback(async () => {
    updateState({ sendingReminders: true }); // Added this line
    try {
      const emails =
        state.selectedTeamMembers.length > 0
          ? state.selectedTeamMembers
              .filter((member) => member.email)
              .map((member) => member.email)
          : state.complianceData
              .filter((item) => !item.is_compliant && item.email)
              .map((item) => item.email);

      if (emails.length === 0) {
        toast.warn("No valid emails to send reminders to.");
        return;
      }

      await axios.post(
        `${API_BASE_URL}/send-reminders`,
        { emails },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Reminders sent successfully.");
    } catch (err) {
      console.error("Error sending reminders:", err);
      toast.error("Error sending reminders.");
    } finally {
      updateState({ sendingReminders: false }); // Added this line
    }
  }, [state.selectedTeamMembers, state.complianceData, token]);

  const exportToExcel = useCallback(() => {
    if (state.reportData.length === 0) {
      toast.warn("No data available to export.");
      return;
    }

    const worksheetData = state.reportData.map((item) => ({
      Employee: item.employee_name,
      Department: item.department,
      "Focus Area": item.focus_area,
      "Hours Logged": formatHours(item.hours_logged),
    }));
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Team Members Report");
    XLSX.writeFile(workbook, "TeamMembersReport.xlsx");
  }, [state.reportData]);

  const exportToPDF = useCallback(() => {
    if (state.reportData.length === 0) {
      toast.warn("No data available to export.");
      return;
    }

    const doc = new jsPDF();
    doc.text("Team Members Report", 14, 16);
    doc.autoTable({
      startY: 20,
      head: [["Employee", "Department", "Focus Area", "Hours Logged"]],
      body: state.reportData.map((item) => [
        item.employee_name,
        item.department,
        item.focus_area,
        formatHours(item.hours_logged),
      ]),
    });
    doc.save("TeamMembersReport.pdf");
  }, [state.reportData]);

  const formatHours = useMemo(
    () => (hours) => {
      hours = parseFloat(hours);
      const h = Math.floor(hours);
      const m = Math.round((hours - h) * 60);
      return `${h} hrs ${m} min`;
    },
    []
  );

  const getColorForFocusArea = useMemo(
    () => (focusArea) => {
      const hash = focusArea
        .split("")
        .reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
      return COLORS[Math.abs(hash) % COLORS.length];
    },
    []
  );

  return (
    <Sidebar>
      <div className="p-6 bg-base-200 min-h-screen">
        <h1 className="text-4xl font-bold mb-8 text-base-content">
          Supervisor Dashboard
        </h1>

        {/* Filter Section */}
        <div className="card bg-base-100 shadow-xl mb-6">
          <div className="card-body">
            {/* Rearranged Filter Layout: Date Range First */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {/* Date Range Pickers */}
              <div className="col-span-1 md:col-span-2 lg:col-span-3">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text font-semibold">
                        Start Date *
                      </span>
                    </label>
                    <DatePicker
                      selected={state.startDate}
                      onChange={(date) => updateState({ startDate: date })}
                      placeholderText="Select Start Date"
                      dateFormat="yyyy-MM-dd"
                      className="input input-bordered w-full"
                      maxDate={state.endDate || new Date()}
                      isClearable
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text font-semibold">
                        End Date *
                      </span>
                    </label>
                    <DatePicker
                      selected={state.endDate}
                      onChange={(date) => updateState({ endDate: date })}
                      placeholderText="Select End Date"
                      dateFormat="yyyy-MM-dd"
                      className="input input-bordered w-full"
                      minDate={state.startDate}
                      maxDate={new Date()}
                      isClearable
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
                {/* Alert message for missing dates */}
                {(!state.startDate || !state.endDate) && (
                  <div className="alert alert-warning mt-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current flex-shrink-0 h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01"
                      />
                    </svg>
                    <span>
                      Please select both start date and end date to proceed.
                    </span>
                  </div>
                )}
              </div>

              {/* Team Members Selector */}
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-semibold">Team Members</span>
                </label>
                <Select
                  options={state.teamMembersList}
                  isMulti
                  onChange={(options) =>
                    updateState({ selectedTeamMembers: options || [] })
                  }
                  placeholder="Select Team Members"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isDisabled={
                    !state.startDate || !state.endDate || state.loading
                  }
                />
              </div>

              {/* Focus Areas Selector */}
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-semibold">Focus Areas</span>
                </label>
                <Select
                  options={state.focusAreas}
                  isMulti
                  onChange={(options) =>
                    updateState({ selectedFocusAreas: options || [] })
                  }
                  placeholder="Select Focus Areas"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isDisabled={
                    !state.startDate ||
                    !state.endDate ||
                    state.focusAreas.length === 0 ||
                    state.loading
                  }
                />
              </div>

              {/* Fetch Data Button */}
              <div className="form-control mt-4 col-span-1 md:col-span-2 lg:col-span-3">
                <button
                  onClick={() => {
                    fetchReportData();
                    fetchComplianceData();
                  }}
                  className={`btn btn-primary w-full ${
                    (!state.startDate || !state.endDate || state.loading) &&
                    "btn-disabled"
                  }`}
                  disabled={!state.startDate || !state.endDate || state.loading}
                  aria-disabled={
                    !state.startDate || !state.endDate || state.loading
                  }
                >
                  {state.loading ? (
                    <>
                      <span className="loading loading-spinner"></span>
                      Fetching Data...
                    </>
                  ) : (
                    "Fetch Data"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Export and Reminder Buttons */}
        <div className="flex flex-wrap justify-end space-x-4 mb-6">
          <button
            onClick={exportToExcel}
            className="btn btn-success"
            disabled={state.reportData.length === 0 || state.loading}
            aria-disabled={state.reportData.length === 0 || state.loading}
          >
            Export to Excel
          </button>
          <button
            onClick={exportToPDF}
            className="btn btn-error"
            disabled={state.reportData.length === 0 || state.loading}
            aria-disabled={state.reportData.length === 0 || state.loading}
          >
            Export to PDF
          </button>
          <button
            onClick={sendReminders}
            className="btn btn-warning"
            disabled={
              state.complianceData.length === 0 ||
              state.loading ||
              state.sendingReminders
            }
            aria-disabled={
              state.complianceData.length === 0 ||
              state.loading ||
              state.sendingReminders
            }
          >
            {state.sendingReminders ? (
              <>
                <span className="loading loading-spinner"></span>
                Sending Reminders...
              </>
            ) : (
              "Send Reminders"
            )}
          </button>
        </div>

        {/* Compliance Section */}
        <div className="card bg-base-100 shadow-xl mb-8">
          <div className="card-body">
            <h2 className="card-title text-2xl mb-4">Compliance Status</h2>
            {state.loading ? (
              <div className="text-center text-base-content/60">
                <span className="loading loading-spinner loading-lg"></span>
                <div className="mt-4">Loading data...</div>
              </div>
            ) : state.complianceData.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Employee</th>
                      <th>Compliance %</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.complianceData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.employee_name}</td>
                        <td>{item.compliance_percentage}%</td>
                        <td>
                          {item.compliance_percentage >= 100 ? (
                            <span className="badge badge-success">
                              Compliant
                            </span>
                          ) : (
                            <span className="badge badge-error">
                              Non-Compliant
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center text-base-content/60">
                No compliance data available.
              </div>
            )}
          </div>
        </div>

        {/* Focus Areas Chart */}
        <div className="card bg-base-100 shadow-xl mb-8">
          <div className="card-body">
            <h2 className="card-title text-2xl mb-4">Focus Areas</h2>
            {state.loading ? (
              <div className="text-center text-base-content/60">
                <span className="loading loading-spinner loading-lg"></span>
                <div className="mt-4">Loading data...</div>
              </div>
            ) : state.chartData.length > 0 ? (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={state.chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="focus_area" />
                  <YAxis allowDecimals={false} />
                  <Tooltip
                    formatter={(value) => formatHours(value)}
                    labelFormatter={(label) => `Focus Area: ${label}`}
                  />
                  <Legend />
                  <Bar dataKey="hours" name="Hours Logged">
                    {state.chartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={getColorForFocusArea(entry.focus_area)}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div className="text-center text-base-content/60">
                No data available for the selected filters.
              </div>
            )}
          </div>
        </div>

        {/* Data Table */}
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title text-2xl mb-4">Team Members Report</h2>
            {state.loading ? (
              <div className="text-center text-base-content/60">
                <span className="loading loading-spinner loading-lg"></span>
                <div className="mt-4">Loading data...</div>
              </div>
            ) : state.reportData.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Employee</th>
                      <th>Department</th>
                      <th>Focus Area</th>
                      <th>Hours Logged</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.reportData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.employee_name}</td>
                        <td>{item.department}</td>
                        <td>{item.focus_area}</td>
                        <td>{formatHours(item.hours_logged)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center text-base-content/60">
                No data available for the selected filters.
              </div>
            )}
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default SupervisorDashboard;
