// MyCalendar.jsx

import Sidebar from "./Sidebar";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

Modal.setAppElement("#root");

const MyCalendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [view, setView] = useState("timeGridWeek");

  // Fetch the token from the Redux store
  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);

  const fetchActivities = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://workspace.optiven.co.ke/api/work-diary/calendar-activities/${user.user_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request headers
          },
        }
      );

      const activities = response.data
        .filter(
          (activity) =>
            activity.date &&
            activity.start_time &&
            activity.end_time &&
            activity.activity_description
        )
        .map((activity) => {
          const date = moment(activity.date).format("YYYY-MM-DD");
          const start = new Date(`${date}T${activity.start_time}`);
          const end = new Date(`${date}T${activity.end_time}`);

          return {
            title: activity.activity_description,
            start,
            end,
          };
        });

      setEvents(activities);
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  }, [token]);

  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  const WORKING_HOURS_START = useMemo(
    () => new Date().setHours(9, 0, 0, 0),
    []
  );
  const WORKING_HOURS_END = useMemo(() => new Date().setHours(18, 0, 0, 0), []);

  const handleEventClick = (arg) => {
    const event = arg.event;
    setSelectedEvent(event);
    setShowEventDetails(true);
  };

  const closeModal = () => {
    setShowEventDetails(false);
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  return (
    <Sidebar>
      <div className="flex-1 p-6 bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen">
        <div className="max-w-7xl mx-auto">
          <div className="bg-white shadow-xl rounded-lg overflow-hidden">
            <div className="p-6">
              <h1 className="text-3xl font-bold text-gray-800 mb-4">
                Work Calendar
              </h1>

              <div className="bg-white rounded-lg shadow-inner p-4">
                <FullCalendar
                  plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                    listPlugin,
                  ]}
                  headerToolbar={{
                    left: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                  }}
                  footerToolbar={{
                    right: "today prev,next",
                  }}
                  initialView={view}
                  events={events}
                  eventClick={handleEventClick}
                  eventClassNames="cursor-pointer"
                  height={"70vh"}
                  selectable
                  minTime={moment(WORKING_HOURS_START).format("HH:mm:ss")}
                  maxTime={moment(WORKING_HOURS_END).format("HH:mm:ss")}
                  views={["day", "week", "month", "agenda"]}
                  datesSet={(dateInfo) => handleViewChange(dateInfo.view.type)}
                  className="font-sans"
                />
              </div>
            </div>
          </div>

          {showEventDetails && selectedEvent && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg p-8 max-w-md w-full">
                <h2 className="text-2xl font-bold mb-4">Event Details</h2>
                <p>
                  <strong>Title:</strong> {selectedEvent.title}
                </p>
                <p>
                  <strong>Start:</strong>{" "}
                  {moment(selectedEvent.start).format("MMMM Do YYYY, h:mm a")}
                </p>
                <p>
                  <strong>End:</strong>{" "}
                  {moment(selectedEvent.end).format("MMMM Do YYYY, h:mm a")}
                </p>
                <button
                  onClick={closeModal}
                  className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors duration-200"
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default MyCalendar;
