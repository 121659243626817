import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { ChevronUp, ChevronDown } from "react-feather";
import { Link } from "react-router-dom";

const ViewCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [countryFilter, setCountryFilter] = useState("");
  const [genderFilter, setGenderFilter] = useState("");

  // Fetch all customer data from the API
  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://workspace.optiven.co.ke/api/loyalty`
      );
      const { customers } = response.data;
      setCustomers(customers);
      setFilteredCustomers(customers);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    filterCustomers();
  }, [countryFilter, genderFilter, customers]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages()) {
      setCurrentPage(pageNumber);
    }
  };

  const totalPages = () => Math.ceil(filteredCustomers.length / itemsPerPage);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    sortCustomers(key, direction);
  };

  const sortCustomers = (key, direction) => {
    const sortedCustomers = [...filteredCustomers].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setFilteredCustomers(sortedCustomers);
  };

  const filterCustomers = () => {
    const filteredData = customers.filter((customer) => {
      const matchesCountry = countryFilter
        ? customer.country_of_residence === countryFilter
        : true;
      const matchesGender = genderFilter
        ? customer.gender === genderFilter
        : true;
      return matchesCountry && matchesGender;
    });
    setFilteredCustomers(filteredData);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const delta = 2;
    const total = totalPages();
    const rangeStart = Math.max(2, currentPage - delta);
    const rangeEnd = Math.min(total - 1, currentPage + delta);

    pageNumbers.push(
      <button
        key={1}
        className={`join-item btn ${currentPage === 1 ? "btn-active" : ""}`}
        onClick={() => handlePageChange(1)}
      >
        1
      </button>
    );

    if (rangeStart > 2) {
      pageNumbers.push(
        <button key="left-ellipsis" className="join-item btn btn-disabled">
          ...
        </button>
      );
    }

    for (let i = rangeStart; i <= rangeEnd; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`join-item btn ${
            currentPage === i ? "btn-active" : "btn-outline"
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    if (rangeEnd < total - 1) {
      pageNumbers.push(
        <button key="right-ellipsis" className="join-item btn btn-disabled">
          ...
        </button>
      );
    }

    pageNumbers.push(
      <button
        key={total}
        className={`join-item btn ${
          currentPage === total ? "btn-active" : "btn-outline"
        }`}
        onClick={() => handlePageChange(total)}
      >
        {total}
      </button>
    );

    return pageNumbers;
  };

  const getSortIcon = (key) => {
    // if (sortConfig.key !== key) return null;
    return sortConfig.direction === "asc" ? (
      <ChevronUp className="size-4" />
    ) : (
      <ChevronDown className="size-4" />
    );
  };

  // Unique country list (uppercase) and gender list excluding 'N/A'
  const uniqueCountries = [
    ...new Set(
      customers
        .map(
          (c) => c.country_of_residence && c.country_of_residence.toUpperCase()
        )
        .filter(Boolean)
    ),
  ];
  const uniqueGenders = [
    ...new Set(customers.map((c) => c.gender).filter((g) => g !== "N/A")),
  ];

  // Pagination logic for displaying customers on the current page
  const currentCustomers = filteredCustomers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Sidebar>
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="container mx-auto">
          <div className="flex flex-col w-full items-center">
            <div className="flex gap-4 my-4">
              <input
                type="text"
                className="input input-bordered w-full"
                placeholder="Search"
              />
              <select
                value={countryFilter}
                onChange={(e) => setCountryFilter(e.target.value)}
                className="select select-bordered"
              >
                <option value="">Filter by Country</option>
                {uniqueCountries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              <select
                value={genderFilter}
                onChange={(e) => setGenderFilter(e.target.value)}
                className="select select-bordered"
              >
                <option value="">Filter by Gender</option>
                {uniqueGenders.map((gender) => (
                  <option key={gender} value={gender}>
                    {gender}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {loading ? (
            <div className="flex justify-center items-center h-screen">
              <TailSpin height="80" width="80" color="#4fa94d" visible />
            </div>
          ) : (
            <div className="flex flex-col w-full items-center">
              <div className="overflow-x-auto">
                <table className="table">
                  <thead>
                    <tr>
                      <th
                        className="flex"
                        onClick={() => handleSort("customer_name")}
                      >
                        Name {getSortIcon("customer_name")}
                      </th>
                      <th>National ID</th>
                      <th>Passport ID</th>
                      <th className="flex" onClick={() => handleSort("gender")}>
                        Gender {getSortIcon("gender")}
                      </th>
                      <th>Phone</th>
                      <th>Primary Email</th>
                      <th
                        className="flex"
                        onClick={() => handleSort("country_of_residence")}
                      >
                        Country of Residence{" "}
                        {getSortIcon("country_of_residence")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentCustomers.map((customer) => (
                      <tr key={customer.customer_no}>
                        <td>
                          <Link
                            to={`/view-customers/${customer.customer_no}`}
                            className="hover:underline"
                          >
                            {customer.customer_name}
                          </Link>
                        </td>
                        <td>{customer.national_id || "N/A"}</td>
                        <td>{customer.passport_no || "N/A"}</td>
                        <td>{customer.gender || "N/A"}</td>
                        <td>{customer.phone || "N/A"}</td>
                        <td>{customer.primary_email || "N/A"}</td>
                        <td>{customer.country_of_residence || "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Name</th>
                      <th>National ID</th>
                      <th>Passport ID</th>
                      <th>Gender</th>
                      <th>Phone</th>
                      <th>Primary Email</th>
                      <th>Country of Residence</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="flex justify-between items-center my-4 w-full max-w-xl">
                <div>
                  <label>Items per page:</label>
                  <select
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    className="select select-bordered select-sm ml-2"
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                  </select>
                </div>
                <div className="join">
                  <button
                    className="join-item btn"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    «
                  </button>
                  {renderPagination()}
                  <button
                    className="join-item btn"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    »
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default ViewCustomers;
