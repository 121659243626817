import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import logo from "../../assets/optiven-logo-full.png";
import AOS from "aos";
import "aos/dist/aos.css"; // Add AOS styles
import img from "../assets/img/img.jpeg";
import cal from "../assets/img/calendar-svgrepo-com.svg";
import search from "../assets/img/magnifying-glass-find-search-svgrepo-com.svg";
import CenteredSVG from "./SVG";

// Create an array of custom banner filenames or file paths
const customBannerFiles = [
  "Website.jpg",
  "nakuru.jpeg",
  "nanyuki.jpeg",
  "vipingo.jpeg",
  "malindi.jpeg",
];

// Import and create an array of custom banner images dynamically
const customBanners = customBannerFiles.map((bannerFile) =>
  require(`../assets/custom-banners/${bannerFile}`)
);

const MapHome = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      offset: 200, 
      easing: "ease-in-out", 
      delay: 100, 
    });
    // Scroll to the top when the component is mounted
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }, []);
  const [likedMaps, setLikedMaps] = useState([]);
  const [mapsData, setMapsData] = useState([
    {
      id: 17,
      title: "Achievers’ Paradise – Ngong, Kimuka Phase 2",
      imageUrl:
        "https://www.optiven.co.ke/wp-content/uploads/2024/06/Achievers-Paradise-Ngong-Phase2.jpeg",
      description:
      "",
      rating: "⭐⭐⭐⭐",
      features: [
        "Compacted murram roads",
        "Electricity on site",
        "Piped water to every plot",
        "Solar street lights",
        "Mesh fence with concrete poles",
        "On-site caretaker",
        "Efficient water management and drainage system",
        "Beautiful entry gate",
      ],
      link: "/achievers-phase-2",
    },
    {
      id: 13,
      title: "Malindi Phase 7 Gardens",
      imageUrl:
        "https://www.optiven.co.ke/wp-content/uploads/2024/01/malindi-gardens-phase-74.jpeg",
      description:
      "Embark on an enriching journey into Malindi’s promising future with Malindi Phase 7 by Optiven. Positioned at the heart of Malindi, this exclusive project goes beyond being a property; it’s a commitment to enduring value and prosperity, redefining modern living and offering an unparalleled investment opportunity. Malindi, with its celebrated rich cultural heritage, becomes more than a destination—it stands as a symbol of tranquility and growth.",
      rating: "⭐",
      features: [
        "Rich Red Soil",
        "Water Lines Facilitated by Mawasco",
        "Power Lines and Fiber Optics Available within the neighborhood",
      ],
      link: "/malindi-7",
    },
    {
      id: 13,
      title: "Vuyanzi Gardens - Kitale",
      imageUrl:
        "https://www.optiven.co.ke/wp-content/uploads/2023/12/tulivugardenskonza.jpeg",
      description:
        "A Serene Haven in the Heart of Kitale Metropolis.Seize this golden opportunity to safeguard your future in the flourishing heart of the Western region, the dynamic urban center, and the esteemed headquarters of Trans Nzoia County.",
      rating: "⭐⭐⭐⭐",
      features: [
        "Internal Murram-Compacted Roads",
        "Mesh Fence",
        "Electricity in Neighbourhood",
        "Water from Kibirichia Project within Neighbourhood",
      ],
      link: "/vuyanzi-g",
    },
    {
      id: 1,
      title: "Joy Lovers Club - Malindi",
      imageUrl:
        "https://www.optiven.co.ke/wp-content/uploads/2023/11/Joy-Lovers-Club-Malindi.jpeg",
      description:
        "A HIGHLY selected high End and luxurious gated community for those who love and need to LIVE / HOLIDAY/ RETIRE in most POSH area of Malindi’s Top Comfort. Installments upto 24 Months.",
      rating: "⭐⭐⭐⭐⭐",
      features: [
        "Beautiful Gates",
        "Secure Perimeter Wall",
        "Security",
        "Water On Site",
        "Solar Street Lights",
        "Cabro Roads",
        "Caretaker On Site",
      ],
      link: "/joy-lovers",
      projectLink:
        "https://www.optiven.co.ke/wp-content/uploads/2023/11/Joy-Lovers-Club-Malindi.jpeg",
    },
    {
      id: 2,
      title: "Achievers Business Park",
      imageUrl:
        "https://www.optiven.co.ke/wp-content/uploads/2023/11/Business-park.jpg",
      description:
        "Strategically positioned on tarmac, Proximity to Kimuka Trading Center, Thriving neighborhood, Breathtaking scenic views of Ngong Hills",
      rating: "⭐⭐⭐",
      features: [
        "Strategically Positioned on Tarmac",
        "Proximity to Kimuka Trading Center",
        "Thriving Neighborhood",
        "Breaktaking Scenic Views of Ngong Hills",
      ],
      link: "/achievers-park",
      projectLink:
        "https://www.optiven.co.ke/properties/achievers-business-park-kimuka/",
    },
    {
      id: 12,
      title: "Kibali Cha Watoto",
      imageUrl:

        "https://www.optiven.co.ke/wp-content/uploads/2023/11/kibali-cha-watoto.jpeg",
      description:
        "The properties benefit from county water access on-site, with Optiven committed to grading the roads and installing pitch marking poles.This property is intended for the young; children, and youths. The project is set to significantly appreciate in value over the next 15 to 20 years.",

      rating: "⭐",
      features: [
        "Rich Red Soil",
        "Fiber Optics Available Within The Neighborhood",
        "Water Lines Facilitated by Mawasco",
        "Power Lines Within The Neighborhood",
      ],
      link: "/wetu-gardens",

      projectLink: "",

    },
    {
      id: 6,
      title: "Great Oasis Gardens",
      imageUrl:
        "https://www.optiven.co.ke/wp-content/uploads/2023/11/Great-Oasis-Gardens-Nanyuki.jpg",
      description:
        "The Great Oasis Gardens – Nanyuki is located along the Nanyuki-Rumuruti Road, which has been proposed for tarmacking. Those who book early will get and secure a special price for their 1/8th acre, 1/4 acre, 1/2 acre, 1 acre, and 5 acres properties.",
      rating: "⭐⭐⭐",
      features: [
        "Murram Roads",
        "Borehole Water",
        "Security",
        "Five Convenient Access Gates",
      ],
      link: "/oasis-gardens",
      projectLink:
        "https://www.optiven.co.ke/properties/the-great-oasis-gardens-nanyuki/",
    },
    {
      id: 3,
      title: "Success Gardens Phase 2 – Gatanga Road, Thika",
      imageUrl:
        "https://www.optiven.co.ke/wp-content/uploads/2023/11/Success.jpg",
      description:
        "Located only less than 9 minutes from Blue Post Hotel Thika; off exit 18 of Thika Superhighway and right on Gatanga Rd. 1/8th plots at Kshs. 2.995M. Installments upto 12 Months",
      rating: "⭐⭐⭐⭐",
      features: [
        "Compacted Murram Roads",
        "Caretaker On Site",
        "Gated Community Security",
      ],
      link: "/success-gardens",
      projectLink:
        "https://www.optiven.co.ke/properties/success-gardens-phase-2-gatanga-road-thika/",
    },
    {
      id: 4,
      title: "Ocean View - Vipingo Ridge",
      imageUrl:
        "https://www.optiven.co.ke/wp-content/uploads/2023/11/Ocean-view.jpg",
      description:
        "This gated community 4.5km from the pristine Indian Ocean, is a haven of peace, tranquillity and extremely prosperous, comfortable life at the Kenyan coast. Special Offer from Kshs 1.75M. Installments upto 12 months",
      rating: "⭐⭐⭐⭐",
      features: [
        "Electricity on Site",
        "Water for every plot",
        "Solar street lights",
        "Internal compacted murram roads for easy access",
        "Manned state-of-the-art gate for your security",
        "Masterfully crafted project design",
      ],
      link: "/vipingo-ridge",
      projectLink:
        "https://www.optiven.co.ke/properties/ocean-view-ridge-vipingo-by-optiven/",
    },
    {
      id: 5,
      title: "Ushindi Gardens",
      imageUrl:
        "https://www.optiven.co.ke/wp-content/uploads/2023/11/Ushindi.jpg",
      description:
        "Ushindi Gardens by Optiven is the best choice for you to make right away. And with proximity to Nakuru City, Ushindi Gardens by Optiven is unique from the others. On a traffic-free day, it will take you only 18 Minutes from Nakuru’s Optiven office, located on the 7th Floor – Golden Life Mall",
      rating: "⭐⭐⭐⭐",
      features: [
        "Compacted Murram Roads",
        "Solar streetlights",
        "Mesh fence for Security",
        "A water tower",
        "A beautiful entry gate"
      ],
      link: "/ushindi-gardens",
      projectLink:
        "https://www.optiven.co.ke/properties/ushindi-gardens-nakuru/",
    },
    {
      id: 7,
      title: "Hekima Phase 3",
      imageUrl:
        "https://www.optiven.co.ke/wp-content/uploads/2023/11/Hekima-Gardens-3.jpg",
      description:
        "This property is right at the Middle of Mt. Kenya and The Aberdare Ranges. Amazing, Breathtaking and Inspiring Views. 1/4 acre plots at Kshs 535,000.",
      rating: "⭐⭐",
      features: [
        "Graded Roads",
        "Marking Fence",
        "Marking Beacons",
        "Mesh and concrete Poles",
      ],
      link: "/hekima-phase-3",
      projectLink:
        "https://www.optiven.co.ke/properties/hekima-gardens-phase-3-nyeri/",
    },
    {
      id: 8,
      title: "Wema Gardens - Narumoru",
      imageUrl:
        "https://www.optiven.co.ke/wp-content/uploads/2023/11/Wema.jpg",
      description:
        "The Place of Good Tidings. Are you looking for a life full of goodness, a life that is entirely characterized by good tidings, a place where you always wake up to meet the humongous Mt. Kenya smiling right through your master bedroom’s window?",
      rating: "⭐⭐",
      features: [
        "Graded Roads",
        "Marking Fence",
        "Marking Beacons",
        "Trees planted along the Roads",
      ],
      link: "/wema-gardens",
    projectLink: "",
    },
  ]);

  const handleHeartClick = (mapId) => {
    if (likedMaps.includes(mapId)) {
      // Unlike the map
      setLikedMaps(likedMaps.filter((id) => id !== mapId));
    } else {
      // Like the map and move it to the top
      setLikedMaps([mapId, ...likedMaps]);
      const updatedMapData = mapsData.slice();
      const likedMapIndex = updatedMapData.findIndex((map) => map.id === mapId);
      if (likedMapIndex !== -1) {
        const [likedMap] = updatedMapData.splice(likedMapIndex, 1);
        updatedMapData.unshift(likedMap);
        setMapsData(updatedMapData);
      }
    }
  };
  const Badge = ({ text = "" }) => (
    <div className="badge" style={{ backgroundColor: "#40a347" }}>
      {text}
    </div>
  );

  return (
    <div>
      <div className="navbar bg-primary">
        <Link className="btn btn-ghost normal-case text-xl" to="/map-home">
          <img src={logo} alt="logo" className="w-40" />
        </Link>
      </div>

      {/* Custom Carousel with custom banners */}
      <div
        className="d-flex justify-content-center"
        style={{
          maxWidth: "100%",
          boxShadow: "0px 0px 60px rgba(0, 128, 0, 0.4)",
        }}
      >
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          autoPlay={true}
          interval={3000}
          showThumbs={false}
        >
          {customBanners.map((banner, index) => (
            <div key={index}>
              <img src={banner} alt={`Custom Banner ${index + 1}`} />
              {/* You can add any content or links for the banners here */}
            </div>
          ))}
        </Carousel>
      </div>

      {/*Landing Page Test*/}
      <>
        <div className="bg-cream">
          <div className="max-w-screen-xl px-8 mx-auto flex flex-col lg:flex-row items-start">
            {/*Left Col*/}
            <div className="flex flex-col w-full lg:w-6/12 justify-center lg:pt-24 items-start text-center lg:text-left mb-5 md:mb-0">
              <h1
                data-aos="fade-right"
                data-aos-once="true"
                className="my-4 text-5xl font-bold leading-tight text-darken"
              >
                <span className="text-green-500">Optiven</span> Online
                Sub-Division Maps
              </h1>
              <p
                data-aos="fade-down"
                data-aos-once="true"
                data-aos-delay={300}
                className="leading-normal text-2xl mb-8"
              >
                Where Innovation Meets Navigation.
              </p>
              <div
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay={700}
                className="w-full md:flex items-center justify-center lg:justify-start md:space-x-5"
              ></div>
            </div>
            {/*Right Col*/}
            <div className="w-full lg:w-6/12 lg:-mt-10 relative" id="girl">
              <img
                data-aos="fade-up"
                data-aos-once="true"
                className="w-10/12 mx-auto 2xl:-mb-20"
                src={img}
                alt="img"
              />
              {/* calendar */}
              <div
                data-aos="fade-up"
                data-aos-delay={300}
                data-aos-once="true"
                className="absolute top-20 -left-6 sm:top-32 sm:left-10 md:top-40 md:left-16 lg:-left-0 lg:top-52 floating-4"
              >
                <img
                  className="bg-white bg-opacity-80 rounded-lg h-12 sm:h-16"
                  src={cal}
                  alt="logo"
                />
              </div>

              {/* ux class */}

              {/* congrats */}
              <div
                data-aos="fade-up"
                data-aos-delay={600}
                data-aos-once="true"
                className="absolute bottom-20 md:bottom-48 lg:bottom-52 -right-6 lg:right-8 floating-4"
              >
                <img
                  className="bg-white bg-opacity-80 rounded-lg h-12 sm:h-16"
                  src={search}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="text-white -mt-14 sm:-mt-24 lg:-mt-36 z-40 relative">
            <svg
              className="xl:h-40 xl:w-full"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
                fill="#94c930"
              />
            </svg>
            <div className="bg-white w-full h-20 -mt-px" />
          </div>
        </div>
        {/* container */}
        <div
          className="container px-4 lg:px-8 mx-auto max-w-screen-xl text-gray-700 overflow-x-hidden"
          style={{ backgroundColor: "#94c930" }}
        >
          {/* trusted by */}
        </div>
      </>

      <>
        {/* All-In-One Cloud Software. */}
        <div
        data-aos="flip-up"
        className="max-w-xl mx-auto text-center mt-2 md:mt-4" 
      >
    
          <h1 className="font-bold text-darken my-3 text-2xl">
            All-In-One{" "}
            <span className="text-yellow-500">Map Update Software.</span>
          </h1>
          <p className="leading-relaxed text-gray-500">
            Unlock the Power of Seamless Mapping with our All-In-One Map Update
            Software - Where Innovation Meets Navigation
          </p>
        </div>
        {/* card */}
        <div className="grid md:grid-cols-3 gap-14 md:gap-5 mt-20">
          <div
            data-aos="fade-up"
            className="bg-white shadow-xl p-6 text-center rounded-xl"
          >
            <div
              style={{ background: "#5B72EE" }}
              className="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
            >
              <svg
                className="w-6 h-6 text-white"
                viewBox="0 0 33 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.75 23H8.25V28.75H24.75V23ZM32.3984 9.43359L23.9852 0.628906C23.5984 0.224609 23.0742 0 22.5242 0H22V11.5H33V10.952C33 10.3859 32.7852 9.83789 32.3984 9.43359ZM19.25 12.2188V0H2.0625C0.919531 0 0 0.961328 0 2.15625V43.8438C0 45.0387 0.919531 46 2.0625 46H30.9375C32.0805 46 33 45.0387 33 43.8438V14.375H21.3125C20.1781 14.375 19.25 13.4047 19.25 12.2188ZM5.5 6.46875C5.5 6.07164 5.80766 5.75 6.1875 5.75H13.0625C13.4423 5.75 13.75 6.07164 13.75 6.46875V7.90625C13.75 8.30336 13.4423 8.625 13.0625 8.625H6.1875C5.80766 8.625 5.5 8.30336 5.5 7.90625V6.46875ZM5.5 12.2188C5.5 11.8216 5.80766 11.5 6.1875 11.5H13.0625C13.4423 11.5 13.75 11.8216 13.75 12.2188V13.6562C13.75 14.0534 13.4423 14.375 13.0625 14.375H6.1875C5.80766 14.375 5.5 14.0534 5.5 13.6562V12.2188ZM27.5 39.5312C27.5 39.9284 27.1923 40.25 26.8125 40.25H19.9375C19.5577 40.25 19.25 39.9284 19.25 39.5312V38.0938C19.25 37.6966 19.5577 37.375 19.9375 37.375H26.8125C27.1923 37.375 27.5 37.6966 27.5 38.0938V39.5312ZM27.5 21.5625V30.1875C27.5 30.9817 26.8847 31.625 26.125 31.625H6.875C6.11531 31.625 5.5 30.9817 5.5 30.1875V21.5625C5.5 20.7683 6.11531 20.125 6.875 20.125H26.125C26.8847 20.125 27.5 20.7683 27.5 21.5625Z"
                  fill="white"
                />
              </svg>
            </div>
            <h1 className="font-medium text-xl mb-3 lg:px-14 text-darken">
              Subdivision Maps for Land Development
            </h1>
            <p className="px-4 text-gray-500">
              Effortlessly manage your land development projects with detailed
              subdivision maps. Visualize land divisions and property
              boundaries.
            </p>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay={150}
            className="bg-white shadow-xl p-6 text-center rounded-xl"
          >
            <div
              style={{ background: "#F48C06" }}
              className="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
            >
              <svg
                className="w-6 h-6 text-white"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 0C11.0532 0 10.2857 0.767511 10.2857 1.71432V5.14285H13.7142V1.71432C13.7142 0.767511 12.9467 0 12 0Z"
                  fill="#F5F5FC"
                />
                <path
                  d="M36 0C35.0532 0 34.2856 0.767511 34.2856 1.71432V5.14285H37.7142V1.71432C37.7143 0.767511 36.9468 0 36 0Z"
                  fill="#F5F5FC"
                />
                <path
                  d="M42.8571 5.14282H37.7143V12C37.7143 12.9468 36.9468 13.7143 36 13.7143C35.0532 13.7143 34.2857 12.9468 34.2857 12V5.14282H13.7142V12C13.7142 12.9468 12.9467 13.7143 11.9999 13.7143C11.0531 13.7143 10.2856 12.9468 10.2856 12V5.14282H5.14285C2.30253 5.14282 0 7.44535 0 10.2857V42.8571C0 45.6974 2.30253 48 5.14285 48H42.8571C45.6975 48 48 45.6974 48 42.8571V10.2857C48 7.44535 45.6975 5.14282 42.8571 5.14282ZM44.5714 42.8571C44.5714 43.8039 43.8039 44.5714 42.857 44.5714H5.14285C4.19605 44.5714 3.42854 43.8039 3.42854 42.8571V20.5714H44.5714V42.8571Z"
                  fill="#F5F5FC"
                />
                <path
                  d="M13.7142 23.9999H10.2857C9.33889 23.9999 8.57138 24.7674 8.57138 25.7142C8.57138 26.661 9.33889 27.4285 10.2857 27.4285H13.7142C14.661 27.4285 15.4285 26.661 15.4285 25.7142C15.4285 24.7674 14.661 23.9999 13.7142 23.9999Z"
                  fill="#F5F5FC"
                />
                <path
                  d="M25.7143 23.9999H22.2857C21.3389 23.9999 20.5714 24.7674 20.5714 25.7142C20.5714 26.661 21.3389 27.4285 22.2857 27.4285H25.7143C26.6611 27.4285 27.4286 26.661 27.4286 25.7142C27.4286 24.7674 26.6611 23.9999 25.7143 23.9999Z"
                  fill="#F5F5FC"
                />
                <path
                  d="M37.7143 23.9999H34.2857C33.3389 23.9999 32.5714 24.7674 32.5714 25.7142C32.5714 26.661 33.3389 27.4285 34.2857 27.4285H37.7143C38.6611 27.4285 39.4286 26.661 39.4286 25.7142C39.4286 24.7674 38.661 23.9999 37.7143 23.9999Z"
                  fill="#F5F5FC"
                />
                <path
                  d="M13.7142 30.8571H10.2857C9.33889 30.8571 8.57138 31.6246 8.57138 32.5714C8.57138 33.5182 9.33889 34.2857 10.2857 34.2857H13.7142C14.661 34.2857 15.4285 33.5182 15.4285 32.5714C15.4285 31.6246 14.661 30.8571 13.7142 30.8571Z"
                  fill="#F5F5FC"
                />
                <path
                  d="M25.7143 30.8571H22.2857C21.3389 30.8571 20.5714 31.6246 20.5714 32.5714C20.5714 33.5182 21.3389 34.2857 22.2857 34.2857H25.7143C26.6611 34.2857 27.4286 33.5182 27.4286 32.5714C27.4286 31.6246 26.6611 30.8571 25.7143 30.8571Z"
                  fill="#F5F5FC"
                />
                <path
                  d="M37.7143 30.8571H34.2857C33.3389 30.8571 32.5714 31.6246 32.5714 32.5714C32.5714 33.5182 33.3389 34.2857 34.2857 34.2857H37.7143C38.6611 34.2857 39.4286 33.5182 39.4286 32.5714C39.4285 31.6246 38.661 30.8571 37.7143 30.8571Z"
                  fill="#F5F5FC"
                />
                <path
                  d="M13.7142 37.7142H10.2857C9.33889 37.7142 8.57138 38.4817 8.57138 39.4286C8.57138 40.3754 9.33889 41.1428 10.2857 41.1428H13.7142C14.661 41.1428 15.4285 40.3753 15.4285 39.4284C15.4285 38.4816 14.661 37.7142 13.7142 37.7142Z"
                  fill="#F5F5FC"
                />
                <path
                  d="M25.7143 37.7142H22.2857C21.3389 37.7142 20.5714 38.4817 20.5714 39.4285C20.5714 40.3754 21.3389 41.1429 22.2857 41.1429H25.7143C26.6611 41.1429 27.4286 40.3754 27.4286 39.4285C27.4286 38.4817 26.6611 37.7142 25.7143 37.7142Z"
                  fill="#F5F5FC"
                />
                <path
                  d="M37.7143 37.7142H34.2857C33.3389 37.7142 32.5714 38.4817 32.5714 39.4285C32.5714 40.3754 33.3389 41.1429 34.2857 41.1429H37.7143C38.6611 41.1429 39.4286 40.3754 39.4286 39.4285C39.4286 38.4817 38.661 37.7142 37.7143 37.7142Z"
                  fill="#F5F5FC"
                />
              </svg>
            </div>
            <h1 className="font-medium text-xl mb-3 lg:px-14 text-darken">
              Land Development Scheduling &amp; Progress Tracking
            </h1>
            <p className="px-4 text-gray-500">
              Efficiently schedule and track the progress of land development
              projects across various locations. Stay organized with project
              timelines and updates.
            </p>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay={300}
            className="bg-white shadow-xl p-6 text-center rounded-xl"
          >
            <div
              style={{ background: "#29B9E7" }}
              className="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
            >
              <svg
                className="w-6 h-6 text-white"
                viewBox="0 0 55 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.25 19.25C11.2836 19.25 13.75 16.7836 13.75 13.75C13.75 10.7164 11.2836 8.25 8.25 8.25C5.21641 8.25 2.75 10.7164 2.75 13.75C2.75 16.7836 5.21641 19.25 8.25 19.25ZM46.75 19.25C49.7836 19.25 52.25 16.7836 52.25 13.75C52.25 10.7164 49.7836 8.25 46.75 8.25C43.7164 8.25 41.25 10.7164 41.25 13.75C41.25 16.7836 43.7164 19.25 46.75 19.25ZM49.5 22H44C42.4875 22 41.1211 22.6102 40.1242 23.5984C43.5875 25.4977 46.0453 28.9266 46.5781 33H52.25C53.7711 33 55 31.7711 55 30.25V27.5C55 24.4664 52.5336 22 49.5 22ZM27.5 22C32.8195 22 37.125 17.6945 37.125 12.375C37.125 7.05547 32.8195 2.75 27.5 2.75C22.1805 2.75 17.875 7.05547 17.875 12.375C17.875 17.6945 22.1805 22 27.5 22ZM34.1 24.75H33.3867C31.5992 25.6094 29.6141 26.125 27.5 26.125C25.3859 26.125 23.4094 25.6094 21.6133 24.75H20.9C15.4344 24.75 11 29.1844 11 34.65V37.125C11 39.4023 12.8477 41.25 15.125 41.25H39.875C42.1523 41.25 44 39.4023 44 37.125V34.65C44 29.1844 39.5656 24.75 34.1 24.75ZM14.8758 23.5984C13.8789 22.6102 12.5125 22 11 22H5.5C2.46641 22 0 24.4664 0 27.5V30.25C0 31.7711 1.22891 33 2.75 33H8.41328C8.95469 28.9266 11.4125 25.4977 14.8758 23.5984Z"
                  fill="white"
                />
              </svg>
            </div>
            <h1 className="font-medium text-xl mb-3 lg:px-14 text-darken lg:h-14 pt-3">
              Customer Experience Enhancement
            </h1>
            <p className="px-4 text-gray-500">
              Elevate customer satisfaction through personalized interactions
              and feedback. Our integrated tools help you create exceptional
              experiences for your clientele.
            </p>
          </div>
        </div>
      </>

      <h1
        className="text-2xl font-bold text-darken my-4 mx-auto text-center text-red-600"
        data-aos="fade-in"
        data-aos-duration="800"
        data-aos-delay="200"
      >
        Counties With Active Projects.
      </h1>

      {/*kenya svg*/}
      <p className="text-center text-sm text-gray-600 mt-0">
        🖱️ Click on individual counties to view current active projects
      </p>
      <CenteredSVG />
      
      <h1
      className="text-2xl font-bold text-darken my-4 mx-auto text-center text-green-600"
      data-aos="fade-in"
      data-aos-duration="800"
      data-aos-delay="200"
    >
      Prominent Properties.
    </h1>

      {/* Cards */}
      <div className="flex flex-wrap justify-center">
        {mapsData.map((map) => (
          <div
            className={`relative flex w-full max-w-[26rem] flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-lg m-4 ${
              likedMaps.includes(map.id) ? "top-0" : ""
            }`}
            key={map.id}
            style={{ boxShadow: "0px 0px 20px rgba(0, 128, 0, 0.4)" }}
          >
            {/* Add this div to create the design effect */}
            <div
              className="absolute w-20 h-20 rounded-full bg-green-00 bg-clip-border z-0 left-4 -top-12 animate-ping"
              style={{ boxShadow: "0px 0px 20px rgba(0, 128, 0, 0.4)" }}
            ></div>

            {/* Add this div to create another design effect 
      <div
        className="absolute w-5 h-5 rounded-full bg-blue-900 bg-clip-border z-0 left-36 -top-12 animate-ping"
        style={{ boxShadow: "0px 0px 20px rgba(0, 128, 0, 0.4)" }}
      ></div>*/}
            <div className="relative mx-4 mt-4 overflow-hidden text-white shadow-lg rounded-xl bg-blue-gray-500 bg-clip-border shadow-blue-gray-500/40">
              <img src={map.imageUrl} alt={map.title} />
              <div className="absolute inset-0 w-full h-full to-bg-black-10 bg-gradient-to-tr from-transparent via-transparent to-black/60"></div>
              <button
                className={`!absolute top-4 right-4 h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-full text-center align-middle font-sans text-xs font-medium uppercase transition-all focus:outline-none ${
                  likedMaps.includes(map.id) ? "text-red-500" : "text-gray-400"
                }`}
                type="button"
                onClick={() => handleHeartClick(map.id)}
                aria-label="Favorite"
              >
                <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    className="w-6 h-6"
                  >
                    <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"></path>
                  </svg>
                </span>
              </button>
            </div>
            <div className="p-6">
              <div className="flex items-center justify-between mb-3">
                <h5 className="block font-sans text-xl antialiased font-medium leading-snug tracking-normal text-blue-gray-900">
                  {map.title}
                  {map.id === 1 && <Badge text="Latest Property🏖" />}
                  {map.id === 2 && <Badge text="Going Fast🔥" />}
                  {map.id === 13 && <Badge text="Recently Listed💥" />}
                  {/*add badges here*/}
                </h5>
                <p className="flex items-center gap-1.5 font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased">
                 
                  {map.rating}
                </p>
              </div>
              <p className="block font-sans text-base antialiased font-light leading-relaxed text-gray-700">
                {map.description}
              </p>
              <div className="inline-flex flex-wrap items-center gap-3 mt-8 group">
                {map.features.map((feature, idx) => (
                  <div
                    key={idx}
                    data-tooltip-target={feature}
                    className={`cursor-pointer rounded-full border ${
                      likedMaps.includes(map.id)
                        ? "border-red-500"
                        : "border-green-500"
                    }/5 bg-green-500/5 p-3 text-green-500 transition-colors hover:border-green-500/10 hover:bg-green-500/10 hover:!opacity-100 group-hover:opacity-70`}
                  >
                    {feature}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col my-4">
              <div className="flex mt-4 justify-center">
                <Link
                  to={map.link}
                  className="btn btn-primary text-white"
                  type="button"
                  data-ripple-light="true"
                  style={{
                    width: "200px",
                    marginRight: "20px",
                    marginLeft: "15px",
                  }}
                >
                  View Map
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* footer*/}
      <div
        className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-full md:px-24 lg:px-8"
        style={{ backgroundColor: "#98e695" }}
      >
        <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="sm:col-span-2">
            <a
              href="/map-home"
              aria-label="Go home"
              title="Company"
              className="inline-flex items-center"
            >
              <div className="flex justify-center text-teal-600 dark:text-teal-300">
                <img src={logo} alt="Your Logo" width="300" height="300" />
              </div>
            </a>
            <div className="mt-6 lg:max-w-sm">
              <p className="text-sm text-gray-800">
                Optiven Group stands as a prominent entity in the African real
                estate sector, dedicated to empowering property investors and
                effecting positive societal transformation. 
              </p>
              <p className="mt-4 text-sm text-gray-800">
              At the core of our
                Real Estate division lies our flagship product: value-added
                plots meticulously tailored to facilitate immediate residential
                settlement, serve commercial purposes, and promise substantial
                future capital appreciation. 
              </p>
              <p className="mt-4 text-sm text-gray-800">
              Discover prime plots available for
              sale in strategic locations including Kiambu, Malindi, Thika
              (Gatanga Road), Machakos, Kitengela, Nyeri, Nanyuki, Kisumu,
              Vipingo, Naro Moru, Malindi, Konza, and Kajiado.
              </p>
            </div>
          </div>
          <div className="space-y-2 text-sm">
            <p className="text-base font-bold tracking-wide text-gray-900">
              Contacts
            </p>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                />
              </svg>
              <p className="mr-1 text-gray-800">Phone:</p>
              <a
                href="tel:850-123-5021"
                aria-label="Our phone"
                title="Our phone"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                +254 790 300300 / +254 723 400500
              </a>
            </div>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3"
                />
              </svg>

              <p className="mr-1 text-gray-800">Email:</p>
              <a
                href="mailto:info@lorem.mail"
                aria-label="Our email"
                title="Our email"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                info@optiven.co.ke
              </a>
            </div>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <a
                href="https://www.google.com/maps"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our address"
                title="Our address"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                Head Office: Absa Towers Loita Street , 2nd Floor
              </a>
            </div>

            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <a
                href="https://www.google.com/maps"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our address"
                title="Our address"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                Optiven Global Office: Zamani Business Park , Karen
              </a>
            </div>
          </div>
          <div>
            <span className="text-base font-bold tracking-wide text-gray-900">
              Social
            </span>
            <div className="flex items-center mt-1 space-x-3">
              <a
                href="https://twitter.com/OptivenLimited"
                className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/optivenlimited/"
                className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                  <circle cx="15" cy="15" r="4" />
                  <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/Optivenlimited/"
                className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                </svg>
              </a>
            </div>
            
          </div>
        </div>
        <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
          <p className="text-sm text-gray-600">
            © Copyright 2023 Optiven Limited. All rights reserved.
          </p>
          <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
            
            <li>
              <a
                href="https://www.optiven.co.ke/privacy-policy/"
                className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                Privacy Policy
              </a>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MapHome;
