const geojsonData = {
  type: "FeatureCollection",

  features: [
    
    {
      type: "Feature",
      id: "sm3b73b366",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.48599934, 35.55371846],
            [-175.47053239, 35.09653637],
            [-174.37189958, 35.11451207],
            [-174.38222955, 35.57031745],
            [-175.48599934, 35.55371846],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD08",
      },
    },
    {
      type: "Feature",
      id: "sm6f515a7a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.38222955, 35.57031745],
            [-174.39455031, 36.11059258],
            [-175.50439236, 36.09401553],
            [-175.48599934, 35.55371846],
            [-174.38222955, 35.57031745],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD07",
      },
    },
    {
      type: "Feature",
      id: "sm760a0eaa",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.39455031, 36.11059258],
            [-174.40611543, 36.61437431],
            [-175.52165732, 36.59781961],
            [-175.50439236, 36.09401553],
            [-174.39455031, 36.11059258],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD06",
      },
    },
    {
      type: "Feature",
      id: "sm470cdff6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.40611543, 36.61437431],
            [-174.41804907, 37.13078093],
            [-175.53947244, 37.11425105],
            [-175.52165732, 36.59781961],
            [-174.40611543, 36.61437431],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD05",
      },
    },
    {
      type: "Feature",
      id: "sm58505930",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.41804907, 37.13078093],
            [-174.42924942, 37.61227177],
            [-175.55619286, 37.59576677],
            [-175.53947244, 37.11425105],
            [-174.41804907, 37.13078093],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD04",
      },
    },
    {
      type: "Feature",
      id: "sm146d6444",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.42924942, 37.61227177],
            [-174.44131203, 38.12736552],
            [-175.57391567, 38.10276927],
            [-175.55619286, 37.59576677],
            [-174.42924942, 37.61227177],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD03",
      },
    },
    {
      type: "Feature",
      id: "sm1f40df35",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.01295209, 39.00769309],
            [-175.44306657, 39.01192367],
            [-175.60236833, 38.909411],
            [-175.57391567, 38.10276927],
            [-174.98146214, 38.11563635],
            [-175.01295209, 39.00769309],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD02",
      },
    },
    {
      type: "Feature",
      id: "smdc7e841f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.98146214, 38.11563635],
            [-174.44131203, 38.12736552],
            [-174.45979021, 38.909411],
            [-174.57514665, 39.00338661],
            [-175.01295209, 39.00769309],
            [-174.98146214, 38.11563635],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD01",
      },
    },
    {
      type: "Feature",
      id: "sm52f290b2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.31081922, 30.05988239],
            [-175.28925776, 29.50080754],
            [-175.13544917, 29.37163919],
            [-174.35541987, 29.39557165],
            [-174.22358393, 29.53904806],
            [-174.23932684, 30.08316082],
            [-175.31081922, 30.05988239],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD18",
      },
    },
    {
      type: "Feature",
      id: "sm3af45751",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.23932684, 30.08316082],
            [-174.25618178, 30.66240768],
            [-175.33328811, 30.63914545],
            [-175.31081922, 30.05988239],
            [-174.23932684, 30.08316082],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD17",
      },
    },
    {
      type: "Feature",
      id: "sma1ecacec",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.25618178, 30.66240768],
            [-174.27168625, 31.19219686],
            [-175.35395672, 31.16895218],
            [-175.33328811, 30.63914545],
            [-174.25618178, 30.66240768],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD16",
      },
    },
    {
      type: "Feature",
      id: "sm0f7883cc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.27168625, 31.19219686],
            [-174.28707873, 31.71524184],
            [-175.37446246, 31.6916718],
            [-175.35395672, 31.16895218],
            [-174.27168625, 31.19219686],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD15",
      },
    },
    {
      type: "Feature",
      id: "sm5907dbf0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.28707874, 31.86465321],
            [-174.30178836, 32.3536891],
            [-175.38914716, 32.33028331],
            [-175.37446247, 31.84112121],
            [-174.28707874, 31.86465321],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD14",
      },
    },
    {
      type: "Feature",
      id: "sm55a5b287",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.30178836, 32.3536891],
            [-174.31810506, 32.8930765],
            [-175.40543621, 32.86981187],
            [-175.38914716, 32.33028331],
            [-174.30178836, 32.3536891],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD13",
      },
    },
    {
      type: "Feature",
      id: "sm79fa4bae",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.31810506, 32.8930765],
            [-174.33442176, 33.42919966],
            [-175.42172526, 33.40607738],
            [-175.40543621, 32.86981187],
            [-174.31810506, 32.8930765],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD12",
      },
    },
    {
      type: "Feature",
      id: "smb60bda5b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.33442176, 33.42919966],
            [-174.35075203, 33.9624733],
            [-175.43802905, 33.93953357],
            [-175.42172526, 33.40607738],
            [-174.33442176, 33.42919966],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD11",
      },
    },
    {
      type: "Feature",
      id: "sm4fc2af67",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.35075203, 33.9624733],
            [-174.36700456, 34.48990987],
            [-175.45425404, 34.46711388],
            [-175.43802905, 33.93953357],
            [-174.35075203, 33.9624733],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD10",
      },
    },
    {
      type: "Feature",
      id: "smaa3d2e0b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.36700456, 34.48990987],
            [-174.38288591, 35.00209891],
            [-175.47053239, 34.99309917],
            [-175.45425404, 34.46711388],
            [-174.36700456, 34.48990987],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD09",
      },
    },
    {
      type: "Feature",
      id: "sma9ffb1f3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.1407051, 23.80695219],
            [-175.14094248, 23.09387564],
            [-174.06428232, 23.09387564],
            [-174.08625498, 23.80695219],
            [-175.1407051, 23.80695219],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD28",
      },
    },
    {
      type: "Feature",
      id: "sm2c38224b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.08625498, 23.80695219],
            [-174.08625498, 24.36860957],
            [-175.15702012, 24.36860957],
            [-175.1407051, 23.80695219],
            [-174.08625498, 23.80695219],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD27",
      },
    },
    {
      type: "Feature",
      id: "sm87cd847a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.08625498, 24.36860957],
            [-174.08625498, 24.42113834],
            [-174.10137897, 25.00596956],
            [-175.17495342, 24.98311136],
            [-175.15702012, 24.36860957],
            [-174.08625498, 24.36860957],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD26",
      },
    },
    {
      type: "Feature",
      id: "sm81615bfe",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.10137897, 25.00596956],
            [-174.1160057, 25.56893653],
            [-175.19146347, 25.54614424],
            [-175.17495342, 24.98311136],
            [-174.10137897, 25.00596956],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD25",
      },
    },
    {
      type: "Feature",
      id: "smb83a3420",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.1160057, 25.56893653],
            [-174.13267953, 26.20747908],
            [-175.21028422, 26.18476442],
            [-175.19146347, 25.54614424],
            [-174.1160057, 25.56893653],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD24",
      },
    },
    {
      type: "Feature",
      id: "sm3652ded6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.13267953, 26.20747908],
            [-174.14815625, 26.79706072],
            [-175.22775371, 26.77442047],
            [-175.21028422, 26.18476442],
            [-174.13267953, 26.20747908],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD23",
      },
    },
    {
      type: "Feature",
      id: "sm97c2fa59",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.14815625, 26.79706072],
            [-174.16301234, 27.36013295],
            [-175.24452267, 27.33756621],
            [-175.22775371, 26.77442047],
            [-174.14815625, 26.79706072],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD22",
      },
    },
    {
      type: "Feature",
      id: "sm490b7d06",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.16301234, 27.36013295],
            [-174.17833585, 27.93793577],
            [-175.26181923, 27.91544693],
            [-175.24452267, 27.33756621],
            [-174.16301234, 27.36013295],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD21",
      },
    },
    {
      type: "Feature",
      id: "smc024f9f4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.75507652, 29.12476691],
            [-175.15192866, 29.12240855],
            [-175.29475093, 29.00717462],
            [-175.26181923, 27.91544693],
            [-174.72304031, 27.92663044],
            [-174.75507652, 29.12476691],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD20",
      },
    },
    {
      type: "Feature",
      id: "sm5d0314c8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.72304031, 27.92663044],
            [-174.17833585, 27.93793577],
            [-174.20710454, 29.01438054],
            [-174.34443354, 29.12720716],
            [-174.75507652, 29.12476691],
            [-174.72304031, 27.92663044],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD19",
      },
    },
    {
      type: "Feature",
      id: "smef9b4e98",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-173.998363805, 15.976110845],
            [-174.998119664, 15.986672564],
            [-174.99812, 14.90921716],
            [-173.987377477, 15.182416535],
            [-173.998363805, 15.976110845],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD39",
      },
    },
    {
      type: "Feature",
      id: "sm8f320a87",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.998119664, 15.986672564],
            [-173.998363805, 15.976110845],
            [-173.989574731, 16.743526958],
            [-175.042064977, 16.724588178],
            [-174.998119664, 15.986672564],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD38",
      },
    },
    {
      type: "Feature",
      id: "sm8f653e9d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.042064977, 16.724588178],
            [-173.989574731, 16.743526958],
            [-173.997067959, 17.436454872],
            [-175.053051305, 17.407250776],
            [-175.042064977, 16.724588178],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD37",
      },
    },
    {
      type: "Feature",
      id: "smf8c30967",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.053051305, 17.407250776],
            [-173.997067959, 17.436454872],
            [-173.987377477, 18.139582178],
            [-175.075023961, 18.12914155],
            [-175.053051305, 17.407250776],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD36",
      },
    },
    {
      type: "Feature",
      id: "sm373d631d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.075023961, 18.12914155],
            [-173.987377477, 18.139582178],
            [-173.98419556, 18.77111996],
            [-175.05825517, 18.7535274],
            [-175.075023961, 18.12914155],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD35",
      },
    },
    {
      type: "Feature",
      id: "smd723edca",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.05825517, 18.7535274],
            [-173.98419556, 18.77111996],
            [-173.99617168, 19.42532168],
            [-175.07057065, 19.40779297],
            [-175.05825517, 18.7535274],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD34",
      },
    },
    {
      type: "Feature",
      id: "smee90f59a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.07057065, 19.40779297],
            [-173.99617168, 19.42532168],
            [-174.00723728, 20.02745522],
            [-175.0819498, 20.00998731],
            [-175.07057065, 19.40779297],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD33",
      },
    },
    {
      type: "Feature",
      id: "smf4832ab8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.0819498, 20.00998731],
            [-174.00723728, 20.02745522],
            [-174.01846658, 20.63614715],
            [-175.09349729, 20.61874268],
            [-175.0819498, 20.00998731],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD32",
      },
    },
    {
      type: "Feature",
      id: "smeb1d4350",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.09349729, 20.61874268],
            [-174.01846658, 20.63614715],
            [-174.035561889, 21.558133603],
            [-175.129955605, 21.3651003],
            [-175.09349729, 20.61874268],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD31",
      },
    },
    {
      type: "Feature",
      id: "sm4b037eeb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.129955605, 21.518489608],
            [-174.039390007, 21.763801175],
            [-174.05266169, 22.47453592],
            [-175.12866135, 22.45733511],
            [-175.129955605, 21.518489608],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD30",
      },
    },
    {
      type: "Feature",
      id: "sm1efe0edc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.12866135, 22.45733511],
            [-174.05266169, 22.47453592],
            [-174.06428232, 23.09387564],
            [-175.14094248, 23.09387564],
            [-175.12866135, 22.45733511],
          ],
        ],
      },
       properties: {
        Unit_Number: "KD29",
      },
    },
    {
      type: "Feature",
      id: "sm790becfd",
      geometry: {
        type: "LineString",
        coordinates: [
          [-178.00837374, 29.27345722],
          [-175.45954549, 29.25428872],
          [-175.74518999, 39.02686087],
          [-179.63435031, 39.05459338],
        ],
      },
       properties: {
        Unit_Number: "KD",
      },
    },
    {
      type: "Feature",
      id: "sm1c659c87",
      geometry: {
        type: "LineString",
        coordinates: [
          [-169.35114714, 38.97776911],
          [-174.11921355, 38.99057895],
          [-173.58637658, 15.88367208],
          [-173.56989718, 15.31755983],
        ],
      },
       properties: {
        Unit_Number: "KD",
      },
    },
    {
      type: "Feature",
      id: "smef470514",
      geometry: {
        type: "LineString",
        coordinates: [
          [-178.05231892, 29.03359364],
          [-175.29475093, 29.00717462],
        ],
      },
       properties: {
        Unit_Number: "KD",
      },
    },
    {
      type: "Feature",
      id: "sm366de905",
      geometry: {
        type: "LineString",
        coordinates: [
          [-174.82233855, 39.40351326],
          [-169.39509245, 39.36954868],
        ],
      },
       properties: {
        Unit_Number: "KD",
      },
    },
  ],
};

export default geojsonData;
