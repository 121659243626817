// Redux
import {
  addNotification,
  fetchNotifications,
} from "./redux/logistics/features/notifications/notificationsSlice";
// utils
import PrivateRoutes from "./utils/PrivateRoutes";
// pages
import {
  AllClientsContacts,
  AllPayments,
  AllSiteVisits,
  AppMenu,
  ApprovedBookings,
  ApprovedSiteVisitsReports,
  ApprovedSVDetails,
  AssignedBookings,
  AssignedSpecialAssignments,
  AssignedVehicleRequests,
  ClientsFeedback,
  ClearedSale,
  CreateBooking,
  CreateSpecialAssignment,
  CreateVehicle,
  CreateWorkPlan,
  Dashboard,
  DonatedBooks,
  DonorReport,
  EditScheduledInterviews,
  EditSiteVisit,
  EditSpecialAssignment,
  EditVehicle,
  EditVisitor,
  EducationReport,
  Environment,
  EnvironmentReport,
  EventReport,
  Health,
  HealthReport,
  HighSchoolPayments,
  HighSchoolSpecific,
  Home,
  InterviewsReports,
  Issuance,
  Initiatives,
  InitiativesReports,
  Login,
  MarketersFeedback,
  MostBookedSitesReports,
  MyClientContacts,
  MySiteVisits,
  Notifications,
  PastRequests,
  Payments,
  PovertyAlleviation,
  PovertyReport,
  Profile,
  RegisterVisitor,
  ReserveParking,
  RequestVehicle,
  Sales,
  ScheduleInterview,
  SiteVisitDetails,
  SiteVisitRequests,
  SiteVisitsSummary,
  Specific,
  Store,
  Survey,
  Users,
  VehicleRequestDetails,
  VehicleRequests,
  ViewDonors,
  ViewDrivers,
  ViewEvents,
  ViewReservedParking,
  ViewScheduleInterviews,
  ViewSites,
  ViewSpecialAssignments,
  ViewVehicles,
  ViewVisitors,
  ViewActivities,
  VisitorsManagementHome,
  VisitorsReports,
  WorkPlanHome,
  CreateActivity,
  ViewWorkPlans,
  DriverItinerary,
  SalesManagerRemarks,
  RegionReports,
  Feedback,
  ViewFeedback,
  IndividualReports,
  ApproveWorkplans,
  WorkPlanDetails,
  UpdateActivities,
  PendingWorkplanDetails,
  ActivityReports,
  ProductivityReports,
  //maps
  JoyLovers,
  MapHome,
  OasisGardens,
  SuccessGardens,
  HekimaPhase3,
  UshindiGardens,
  VipingoRidge,
  AchieversPark,
  WemaGardens,
  WetuGardens,
  RegionalManagerRemarks,
  Malindi,
  Nairobi,
  Kajiado,
  Kiambu,
  Nakuru,
  Nyeri,
  Laikipia,
  Education,
  Kisumu,
  Machakos,
  Eldoret,
  KumpaSprings,
  FoundationHome,
  PaymentReports,
  BookReports,
  // KPIs
  CreateTasks,
  AdminDashboard,
  ViewTasks,
  MonthlySales,
  ReferralBusiness,
  ViewMonthlySales,
  ViewReferal,
  County,
  Hotel,
  Industry,
  RegionalEvent,
  UserDashboard,
  LandingPage,
  ViewRegional,
  MonthlyReports,
  ReferralReports,
  RegionalEventReport,
  MultiTableReports,
  ViewCounty,
  SalesDashboard,
  TulivuGardens,
  CreateProject,
  Projects,
  ProjectInfo,
  CreateValueAddition,
  ValueAdditionDetails,
  EditValueAddition,
  EditProject,
  CreateTask,
  EditTask,
  CreateSupplier,
  ViewSuppliers,
  EditSupplier,
  CreateLabour,
  ViewLabour,
  CreateCasualLabourers,
  ViewCasualLabourers,
  ValueHome,
  VuyanziGardens,
  WorkPlanDashboard,
  CreateConstructionManager,
  ViewConstructionManagers,
  EditCasualLabourer,
  CreateRequisition,
  ViewRequisition,
  ApproveRequisition,
  ApprovedRequisitions,
  CreatePayments,
  ViewPayments,
  FilterPayments,
  EditConstructionManagers,
  EditLabour,
  ValueAdditionReports,
  ViewValueAdditions,
  UpdatePayments,
  ArchiveCasualLabourers,
  Summary,
  CreateSupplierPayments,
  ViewSupplierPayments,
  MaintenanceFilterPayments,
  CasualLabourerPaymentReports,
  SupplierReport,
  ValueAdditionPaymentUpdates,
  ViewMaintenancePayments,
  ViewValueAdditionPayments,
  ValueAdditionFilterPayments,
  CreateUsers,
  ViewUsers,
  EditUsers,
  MaintenanceSupplies,
  ViewMaintenanceSupply,
  ViewMaintenanceSuppliers,
  Kitale,
  Malindi7,
  HekimaPhase4,
  OptivenHomes,
  HomesDash,
  ClientDetails,
  CreateFow,
  ViewFow,
  ApproveFow,
  ConstructionPhasePage,
  ConstClients,
  DesignClients,
  TerminatedClients,
  UploadDesign,
  ViewDesign,
  UploadConstruction,
  ViewConstruction,
  UploadImages,
  ViewImages,
  BookingForm,
  ConferenceRooms,
  RoomSchedule,
  RoomBookingForm,
  ConferenceRoom,
  AllRoomSchedule,
  ClientReports,
  EventBooking,
  BookingSummary,
  VictoryGardens,
  AchieversPhase2,
  ClientNotification,
  ViewConstProgress,
  KithimaniSprings,
  Malindi5,
  Victory1,
  Victory2,
  Victory3,
  WisdomPhase2,
  SystemSurvey,
  ViewSurvey,
  SurveyReports,
  GMCBookingForm,
  GMCConferenceRooms,
  GMCRoomBookingForm,
  GmcConferenceRoom,
  GmcRoomSchedule,
  EntireRoomSchedule,
  VipingoPrime,
  WorkDiary,
  DashboardWorkDiary,
  WorkDiaryEntry,
  MyCalendar,
  ReportsSection,
  SupervisorDashboard,
  HRAdminDashboard,
  LoyaltyHome,
  ViewCustomers,
  CustomerDetails,
  CustomerReports,
} from "./utils/index";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { io } from "socket.io-client";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const socket = io("https://workspace.optiven.co.ke");

    dispatch(fetchNotifications());

    socket.on("siteVisitRejected", (notification) => {
      console.log("Site visit rejected");
      // Update the notifications state
      dispatch(
        addNotification({
          type: "rejected",
          message: "Your site visit request has been rejected :(",
          remarks: notification.remarks,
          timestamp: new Date(notification.timestamp),
          isRead: false,
        })
      );
    });

    socket.on("siteVisitApproved", (notification) => {
      console.log("Site visit approved");
      // Update the notifications state
      dispatch(
        addNotification({
          type: "approved",
          message: "Your site visit request has been approved!",
          remarks: notification.remarks,
          timestamp: new Date(notification.timestamp),
          isRead: false,
          site_visit_id: notification.site_visit_id,
        })
      );
    });

    socket.on("siteVisitCompleted", (notification) => {
      dispatch(
        addNotification({
          type: "completed",
          message: "A site visit has been completed. Please fill the survey",
          site_visit_id: notification.site_visit_id,
          timestamp: new Date(notification.timestamp),
          isRead: false,
        })
      );
    });

    return () => {
      // Remove event listeners
      socket.off("connect");
      socket.off("disconnect");
      socket.off("siteVisitRejected");
      socket.off("siteVisitApproved");
      socket.off("siteVisitCompleted");
    };
  }, [dispatch]);

  return (
    <>
      <Router>
        <Routes>
          {/*Maps , Public Access */}
          <Route path="/map-home" element={<MapHome />} />
          <Route path="/joy-lovers" element={<JoyLovers />} />
          <Route path="/oasis-gardens" element={<OasisGardens />} />
          <Route path="/kumpa-springs" element={<KumpaSprings />} />
          <Route path="/success-gardens" element={<SuccessGardens />} />
          <Route path="/hekima-phase-3" element={<HekimaPhase3 />} />
          <Route path="/ushindi-gardens" element={<UshindiGardens />} />
          <Route path="/vipingo-ridge" element={<VipingoRidge />} />
          <Route path="/achievers-park" element={<AchieversPark />} />
          <Route path="/wema-gardens" element={<WemaGardens />} />
          <Route path="/wetu-gardens" element={<WetuGardens />} />
          <Route path="/tulivu-gardens" element={<TulivuGardens />} />
          <Route path="/vuyanzi-g" element={<VuyanziGardens />} />
          <Route path="/malindi-7" element={<Malindi7 />} />
          <Route path="/achievers-phase-2" element={<AchieversPhase2 />} />
          <Route path="/victory-6" element={<VictoryGardens />} />
          <Route path="/kithimani" element={<KithimaniSprings />} />
          <Route path="/malindi-5" element={<Malindi5 />} />
          <Route path="/victory-1" element={<Victory1 />} />
          <Route path="/victory-2" element={<Victory2 />} />
          <Route path="/victory-3" element={<Victory3 />} />
          <Route path="/wisdom-2" element={<WisdomPhase2 />} />
          <Route path="/vipingo-prime" element={<VipingoPrime />} />

          <Route path="/malindi" element={<Malindi />} />
          <Route path="/nairobi" element={<Nairobi />} />
          <Route path="/kajiado" element={<Kajiado />} />
          <Route path="/kiambu" element={<Kiambu />} />
          <Route path="/nakuru" element={<Nakuru />} />
          <Route path="/nyeri" element={<Nyeri />} />
          <Route path="/laikipia" element={<Laikipia />} />
          <Route path="/kisumu" element={<Kisumu />} />
          <Route path="/machakos" element={<Machakos />} />
          <Route path="/kitale" element={<Kitale />} />
          <Route path="/eldoret" element={<Eldoret />} />
          <Route path="/hekima-4" element={<HekimaPhase4 />} />

          {/* Login */}
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoutes />}>
            {/* App Menu */}
            <Route path="/" element={<AppMenu />} />
            {/* Feedback */}
            <Route path="/view-feedback" element={<ViewFeedback />} />
            <Route path="/feedback" element={<Feedback />} />
            {/* Profile */}
            <Route path="/profile" element={<Profile />} />
            {/* Home */}
            <Route path="/logistics-home" element={<Home />} />
            {/* Dashboard */}
            <Route path="/dashboard" element={<Dashboard />} />
            {/* Site Visit Bookings */}
            <Route path="/my-site-visits" element={<MySiteVisits />} />
            <Route path="/book-site-visit" element={<CreateBooking />} />
            <Route
              path="/assigned-site-visits"
              element={<AssignedBookings />}
            />
            <Route
              path="/approved-site-visits"
              element={<ApprovedBookings />}
            />
            <Route path="/all-site-visits" element={<AllSiteVisits />} />
            <Route
              path="/site-visit-requests"
              element={<SiteVisitRequests />}
            />
            <Route
              path="/site-visit-requests/:id"
              element={<SiteVisitDetails />}
            />
            <Route path="/edit-site-visit/:id" element={<EditSiteVisit />} />
            {/* Sites */}
            <Route path="/view-sites" element={<ViewSites />} />
            {/* Vehicles */}
            <Route path="/create-vehicle" element={<CreateVehicle />} />
            <Route path="/vehicles" element={<ViewVehicles />} />
            <Route path="/request-vehicle" element={<RequestVehicle />} />
            <Route path="/vehicles/:id" element={<EditVehicle />} />
            <Route path="/vehicle-requests" element={<VehicleRequests />} />
            <Route path="/past-vehicle-requests" element={<PastRequests />} />
            <Route
              path="/vehicle-request-details/:id"
              element={<VehicleRequestDetails />}
            />
            {/* Clients */}
            <Route path="/my-clients-contacts" element={<MyClientContacts />} />
            <Route
              path="/all-clients-contacts"
              element={<AllClientsContacts />}
            />
            <Route path="/clients-feedback" element={<ClientsFeedback />} />
            {/* Drivers */}
            <Route path="/drivers" element={<ViewDrivers />} />
            <Route
              path="/assigned-vehicle-requests"
              element={<AssignedVehicleRequests />}
            />
            {/* Users */}
            <Route path="/users" element={<Users />} />
            {/* Notifications */}
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/sv-details/:id" element={<ApprovedSVDetails />} />
            {/* Survey */}
            <Route path="/survey/:id" element={<Survey />} />
            {/* Reports */}
            <Route
              path="/site-visits-summary-reports"
              element={<SiteVisitsSummary />}
            />
            <Route
              path="/approved-site-visit-reports"
              element={<ApprovedSiteVisitsReports />}
            />
            <Route
              path="/most-booked-sites-reports"
              element={<MostBookedSitesReports />}
            />
            <Route
              path="/marketers-feedback-reports"
              element={<MarketersFeedback />}
            />
            <Route path="/driver-itinerary" element={<DriverItinerary />} />
            <Route
              path="/assigned-special-assignments"
              element={<AssignedSpecialAssignments />}
            />
            <Route
              path="/create-special-assignment"
              element={<CreateSpecialAssignment />}
            />
            <Route
              path="/special-assignment/:id"
              element={<EditSpecialAssignment />}
            />
            <Route
              path="/view-special-assignments"
              element={<ViewSpecialAssignments />}
            />
            {/* Visitor Management */}
            <Route
              path="/visitors-management"
              element={<VisitorsManagementHome />}
            />
            <Route path="/register-visitor" element={<RegisterVisitor />} />
            <Route path="/view-visitors" element={<ViewVisitors />} />
            <Route path="/edit-visitor/:id" element={<EditVisitor />} />

            <Route
              path="/view-interviews"
              element={<ViewScheduleInterviews />}
            />
            <Route path="/schedule-interview" element={<ScheduleInterview />} />
            <Route
              path="/edit-scheduled-interviews/:id"
              element={<EditScheduledInterviews />}
            />
            <Route path="/visitors-reports" element={<VisitorsReports />} />
            <Route path="/interviews-reports" element={<InterviewsReports />} />

            {/* Parking */}
            <Route path="/reserve-parking" element={<ReserveParking />} />
            <Route path="/reserved-parking" element={<ViewReservedParking />} />
            {/* Workplan */}
            <Route path="/workplan-dashboard" element={<WorkPlanDashboard />} />
            <Route path="/workplan-home" element={<WorkPlanHome />} />
            <Route path="/create-workplan" element={<CreateWorkPlan />} />
            <Route path="/view-workplans/:id" element={<CreateActivity />} />
            <Route path="/approve-workplans" element={<ApproveWorkplans />} />
            <Route path="/view-activities" element={<ViewActivities />} />
            <Route path="/update-activities" element={<UpdateActivities />} />
            <Route path="/view-workplans" element={<ViewWorkPlans />} />
            <Route path="/rm-remarks" element={<RegionalManagerRemarks />} />
            <Route path="/sm-remarks" element={<SalesManagerRemarks />} />
            <Route path="/individual-reports" element={<IndividualReports />} />
            <Route path="/activity-reports" element={<ActivityReports />} />
            <Route
              path="/productivity-reports"
              element={<ProductivityReports />}
            />
            <Route path="/regional-reports" element={<RegionReports />} />
            <Route
              path="/pending-workplan-details/:id"
              element={<PendingWorkplanDetails />}
            />
            <Route path="/workplan-details/:id" element={<WorkPlanDetails />} />
            {/*Maps */}
            <Route path="/joy-lovers" element={<JoyLovers />} />
            <Route path="/map-home" element={<MapHome />} />
            <Route path="/oasis-gardens" element={<OasisGardens />} />
            <Route path="/kumpa-springs" element={<KumpaSprings />} />
            <Route path="/success-gardens" element={<SuccessGardens />} />
            <Route path="/hekima-phase-3" element={<HekimaPhase3 />} />
            <Route path="/ushindi-gardens" element={<UshindiGardens />} />
            <Route path="/vipingo-ridge" element={<VipingoRidge />} />
            <Route path="/achievers-park" element={<AchieversPark />} />
            <Route path="/wema-gardens" element={<WemaGardens />} />
            <Route path="/wetu-gardens" element={<WetuGardens />} />
            <Route path="/tulivu-gardens" element={<TulivuGardens />} />
            <Route path="/hekima-4" element={<HekimaPhase4 />} />
            <Route path="/kithimani" element={<KithimaniSprings />} />
            <Route path="/malindi-5" element={<Malindi5 />} />
            <Route path="/victory-1" element={<Victory1 />} />
            <Route path="/victory-2" element={<Victory2 />} />
            <Route path="/victory-3" element={<Victory3 />} />
            <Route path="/wisdom-2" element={<WisdomPhase2 />} />
            <Route path="/vipingo-prime" element={<VipingoPrime />} />

            <Route path="/malindi" element={<Malindi />} />
            <Route path="/nairobi" element={<Nairobi />} />
            <Route path="/kajiado" element={<Kajiado />} />
            <Route path="/kiambu" element={<Kiambu />} />
            <Route path="/nakuru" element={<Nakuru />} />
            <Route path="/nyeri" element={<Nyeri />} />
            <Route path="/laikipia" element={<Laikipia />} />
            <Route path="/kisumu" element={<Kisumu />} />
            <Route path="/machakos" element={<Machakos />} />
            <Route path="/eldoret" element={<Eldoret />} />
            <Route path="/vuyanzi-g" element={<VuyanziGardens />} />
            <Route path="/kitale" element={<Kitale />} />
            <Route path="/malindi-7" element={<Malindi7 />} />
            <Route path="/victory-6" element={<VictoryGardens />} />
            <Route path="/achievers-phase-2" element={<AchieversPhase2 />} />

            {/* Optiven Foundation */}
            <Route path="/foundation-home" element={<FoundationHome />} />
            <Route path="/education" element={<Education />} />
            <Route path="/environment" element={<Environment />} />
            <Route path="/health" element={<Health />} />
            <Route
              path="/poverty-alleviation"
              element={<PovertyAlleviation />}
            />
            <Route path="/view-donors" element={<ViewDonors />} />
            <Route path="/issuance" element={<Issuance />} />
            <Route path="/sales" element={<Sales />} />
            <Route path="/store" element={<Store />} />
            <Route path="/view-events" element={<ViewEvents />} />
            <Route path="/poverty-report" element={<PovertyReport />} />
            <Route path="/education-report" element={<EducationReport />} />
            <Route path="/health-report" element={<HealthReport />} />
            <Route path="/environment-report" element={<EnvironmentReport />} />
            <Route path="/donor-report" element={<DonorReport />} />
            <Route path="/event-report" element={<EventReport />} />
            <Route path="/specific/:id" element={<Specific />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/payment-reports" element={<PaymentReports />} />
            <Route path="/book-report" element={<BookReports />} />
            <Route path="/cleared-sale" element={<ClearedSale />} />
            <Route path="/donated-books" element={<DonatedBooks />} />
            <Route path="/initiatives" element={<Initiatives />} />
            <Route
              path="/initiative-reports"
              element={<InitiativesReports />}
            />

            <Route
              path="/highschool-payments"
              element={<HighSchoolPayments />}
            />
            <Route
              path="/highschool-specific"
              element={<HighSchoolSpecific />}
            />
            <Route path="/all-payments" element={<AllPayments />} />

            {/* Value Addition */}
            <Route path="/value-addition-home" element={<ValueHome />} />
            <Route path="/summary" element={<Summary />} />
            <Route path="/create-project" element={<CreateProject />} />

            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:id" element={<ProjectInfo />} />
            <Route path="/create-users" element={<CreateUsers />} />
            <Route path="/view-users" element={<ViewUsers />} />
            <Route path="/view-users/:id/edit-user" element={<EditUsers />} />
            <Route
              path="/projects/:id/add-value-addition/:id"
              element={<CreateValueAddition />}
            />
            <Route
              path="/projects/:id/add-value-addition"
              element={<CreateValueAddition />}
            />

            <Route
              path="/value-additions/:id"
              element={<ValueAdditionDetails />}
            />
            <Route
              path="/view-value-additions"
              element={<ViewValueAdditions />}
            />
            <Route
              path="/maintenance-filter-payments"
              element={<MaintenanceFilterPayments />}
            />
            <Route
              path="/value-addition-filter-payments"
              element={<ValueAdditionFilterPayments />}
            />

            <Route
              path="/value-additions/:id/edit-value-addition"
              element={<EditValueAddition />}
            />
            <Route
              path="/projects/:id/edit-project"
              element={<EditProject />}
            />
            <Route
              path="/value-additions/:id/add-task"
              element={<CreateTask />}
            />
            <Route
              path="/add-construction-manager"
              element={<CreateConstructionManager />}
            />
            <Route
              path="/view-construction-manager"
              element={<ViewConstructionManagers />}
            />

            <Route path="/tasks/:id/edit-task" element={<EditTask />} />
            <Route path="/add-supplier" element={<CreateSupplier />} />
            <Route
              path="/suppliers/:id/edit-supplier"
              element={<EditSupplier />}
            />

            <Route path="/suppliers" element={<ViewSuppliers />} />
            <Route
              path="/create-supplier-payment"
              element={<CreateSupplierPayments />}
            />
            <Route
              path="/maintenance-supply"
              element={<MaintenanceSupplies />}
            />
            <Route
              path="/view-maintenance-supply"
              element={<ViewMaintenanceSupply />}
            />
            <Route
              path="/view-maintenance-suppliers"
              element={<ViewMaintenanceSuppliers />}
            />
            <Route
              path="/view-supplier-payment"
              element={<ViewSupplierPayments />}
            />
            <Route path="/supplier-report" element={<SupplierReport />} />

            <Route path="/add-labour" element={<CreateLabour />} />
            <Route path="/view-labour" element={<ViewLabour />} />
            <Route
              path="/add-casual-labourer"
              element={<CreateCasualLabourers />}
            />
            <Route
              path="/view-casual-labourer"
              element={<ViewCasualLabourers />}
            />
            <Route
              path="/archive-casual-labourer"
              element={<ArchiveCasualLabourers />}
            />
            <Route
              path="/view-casual_labourer/:id/edit-casual_labourer"
              element={<EditCasualLabourer />}
            />
            <Route path="/create_requisition" element={<CreateRequisition />} />
            <Route path="/view_requisition" element={<ViewRequisition />} />
            <Route
              path="/approve_requisitions"
              element={<ApproveRequisition />}
            />
            <Route
              path="/approved_requisitions"
              element={<ApprovedRequisitions />}
            />
            <Route path="/create_payments" element={<CreatePayments />} />
            <Route path="/view_payments" element={<ViewPayments />} />
            <Route
              path="/view-maintenance-payments"
              element={<ViewMaintenancePayments />}
            />
            <Route
              path="/view-value-addition-payments"
              element={<ViewValueAdditionPayments />}
            />
            <Route path="/update_payments" element={<UpdatePayments />} />
            <Route
              path="/value-addition-update-payments"
              element={<ValueAdditionPaymentUpdates />}
            />
            <Route path="/filter_payments" element={<FilterPayments />} />

            <Route
              path="/casual-labourer-payment-report"
              element={<CasualLabourerPaymentReports />}
            />
            <Route
              path="/view-construction-manager/:id/edit-construction-manager"
              element={<EditConstructionManagers />}
            />
            <Route
              path="/view-labour/:id/edit-labour"
              element={<EditLabour />}
            />
            <Route
              path="/value-addition-report"
              element={<ValueAdditionReports />}
            />

            {/*Performance Report*/}
            <Route path="/create-tasks" element={<CreateTasks />} />
            <Route path="/Admin" element={<AdminDashboard />} />
            <Route path="/dash" element={<UserDashboard />} />
            <Route path="/home" element={<LandingPage />} />
            <Route path="/view-tasks" element={<ViewTasks />} />
            <Route path="/monthly-sales" element={<MonthlySales />} />
            <Route path="/referal" element={<ReferralBusiness />} />
            <Route path="/view-monthly-sales" element={<ViewMonthlySales />} />
            <Route path="/view-referal" element={<ViewReferal />} />
            <Route path="/county" element={<County />} />
            <Route path="/regional-events" element={<RegionalEvent />} />
            <Route path="/view-regional-events" element={<ViewRegional />} />
            <Route path="/monthly-report" element={<MonthlyReports />} />
            <Route path="/referral-report" element={<ReferralReports />} />
            <Route
              path="/regional-event-report"
              element={<RegionalEventReport />}
            />
            <Route path="/activation-report" element={<MultiTableReports />} />
            <Route path="/view-county" element={<ViewCounty />} />
            <Route path="/sales-dash" element={<SalesDashboard />} />
            <Route path="/create-fow" element={<CreateFow />} />
            <Route path="/view-fow" element={<ViewFow />} />
            <Route path="/approve-fow" element={<ApproveFow />} />

            {/*Optiven Homes*/}
            <Route path="/optiven-home" element={<OptivenHomes />} />
            <Route path="/optiven-dash" element={<HomesDash />} />
            <Route path="/client/:clientId" element={<ClientDetails />} />
            <Route
              path="/const-phase/:clientId"
              element={<ConstructionPhasePage />}
            />
            <Route path="/const-clients" element={<ConstClients />} />
            <Route path="/design-clients" element={<DesignClients />} />
            <Route
              path="/terminated-contracts"
              element={<TerminatedClients />}
            />
            <Route path="/upload-design" element={<UploadDesign />} />
            <Route path="/view-design" element={<ViewDesign />} />
            <Route path="/upload-const" element={<UploadConstruction />} />
            <Route path="/view-const" element={<ViewConstruction />} />
            <Route path="/upload-archi-images" element={<UploadImages />} />
            <Route path="/view-archi-images" element={<ViewImages />} />
            <Route path="/client-reports" element={<ClientReports />} />
            <Route path="/client-notif" element={<ClientNotification />} />
            <Route
              path="/view-progress/:clientId"
              element={<ViewConstProgress />}
            />

            {/* System Survey */}
            <Route path="/survey" element={<SystemSurvey />} />
            <Route path="/view-survey" element={<ViewSurvey />} />
            <Route path="/survey-reports" element={<SurveyReports />} />

            {/* Conference */}
            <Route path="/booking" element={<BookingForm />} />
            <Route path="/conf-rooms" element={<ConferenceRooms />} />
            <Route
              path="/conf-rooms/:roomId/booking"
              element={<RoomBookingForm />}
            />
            <Route path="/room-schedule" element={<RoomSchedule />} />
            <Route path="/all-rooms" element={<ConferenceRoom />} />
            <Route path="/entire-schedule" element={<AllRoomSchedule />} />
            <Route path="/event-booking" element={<EventBooking />} />
            <Route path="/booking-summary" element={<BookingSummary />} />

            {/* Work diary */}
            <Route path="/work-diary" element={<WorkDiary />} />
            <Route path="/calendar" element={<MyCalendar />} />
            <Route
              path="/dashboard-work-diary"
              element={<DashboardWorkDiary />}
            />
            <Route path="/work-diary-entry" element={<WorkDiaryEntry />} />
            <Route path="/reports" element={<ReportsSection />} />
            <Route
              path="/supervisor-dashboard"
              element={<SupervisorDashboard />}
            />
            <Route path="/hr-admin-dashboard" element={<HRAdminDashboard />} />

            {/* Loyalty */}
            <Route path="/loyalty-home" element={<LoyaltyHome />} />
            <Route path="/view-customers" element={<ViewCustomers />} />
            <Route
              path="/view-customers/:customer_id"
              element={<CustomerDetails />}
            />
            <Route path="/customer-reports" element={<CustomerReports />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;
